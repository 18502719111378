<template>

  <v-row dense>
    <v-col cols="12" sm="4">
      <provincia-combobox v-model="value[0].value" :is-required="false"  @input="$emit('update')" clearable multiple></provincia-combobox>
    </v-col>

    <v-col cols="12" sm="4">
      <estado-expediente-combobox v-model="value[1].value" :is-required="false"  @input="$emit('update')" clearable></estado-expediente-combobox>
    </v-col>

    <v-col cols="12" sm="4">
      <etapa-expediente-combobox v-model="value[2].value" :is-required="false"  @input="$emit('update')" clearable></etapa-expediente-combobox>
    </v-col>


    <v-col cols="12" sm="4">
      <patrocinante-combobox v-model="value[3].value" :is-required="false"  @input="$emit('update')" clearable></patrocinante-combobox>
    </v-col>

    <v-col cols="12" sm="4">
      <cliente-combobox v-model="value[4].value" :is-required="false" @input="$emit('update')" clearable></cliente-combobox>
    </v-col>

    <v-col cols="12" sm="4">
      <beneficiario-combobox v-model="value[5].value" :is-required="false" @input="$emit('update')" clearable></beneficiario-combobox>
    </v-col>
  </v-row>

</template>

<script>
import ProvinciaCombobox from "@/modules/wolke/components/ProvinciaCombobox/ProvinciaCombobox.vue";
import EstadoExpedienteCombobox from "@/modules/wolke/components/EstadoExpedienteCombobox/EstadoExpedienteCombobox.vue";
import PatrocinanteCombobox from "@/modules/wolke/components/PatrocinanteCombobox/PatrocinanteCombobox.vue";
import ClienteCombobox from "@/modules/wolke/components/ClienteCombobox/ClienteCombobox.vue";
import EtapaExpedienteCombobox from "@/modules/wolke/components/EtapaExpedienteCombobox/EtapaExpedienteCombobox.vue";
import BeneficiarioCombobox from "@/modules/wolke/components/BeneficiarioCombobox/BeneficiarioCombobox.vue";

export default {
  name: "ExpedienteFilters",
  props: {
    value: {type: Array, required: true}
  },
  components: {
    BeneficiarioCombobox,
    EtapaExpedienteCombobox, ClienteCombobox, PatrocinanteCombobox, EstadoExpedienteCombobox, ProvinciaCombobox},
}
</script>

<style scoped>

</style>
