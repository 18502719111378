<template>
  <div>


    <h5 class="text-h6">Movimientos</h5>

    <movimientos-expediente :movimientos="movimientos"></movimientos-expediente>

    <!--    AGREGAR MOVIMIENTO-->
    <agregar-movimiento-expediente
        v-if="etapaId && readonly === false"
        :expediente-id="expedienteId"
        :etapa-id="etapaId"
        @movimientoAgregado="onMovimientoAgregado"
        @movimientoPreparado="onMovimientoPreparado"
        :updating="updating"
    ></agregar-movimiento-expediente>



    <v-card-text v-else-if="readonly === false">
      <v-alert type="info">Se requiere definir la etapa actual</v-alert>
    </v-card-text>


  </div>

</template>

<script>
import MovimientosExpediente from "../../../../../components/ListaMovimientosExpediente";
import {InputErrorsByProps, RequiredRule} from "@dracul/common-frontend";
import AgregarMovimientoExpediente
  from "@/modules/wolke/components/AgregarMovimientoExpediente/AgregarMovimientoExpediente.vue";


export default {
  name: "ExpedienteMovimientosForm",
  components: {AgregarMovimientoExpediente, MovimientosExpediente},
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    expedienteId: {type: String, required: false},
    etapaId: {type: String, required: false},
    movimientos: {type: Array, required: false},
    readonly: {type: Boolean, default: false},
    updating: {type: Boolean, required: false}
  },
  methods: {
    onMovimientoAgregado(expediente){
      this.$emit('movimientoAgregado',expediente);
    },
    onMovimientoPreparado(movimiento){
      this.$emit('movimientoPreparado',movimiento);
    }
  }


}
</script>

<style scoped>

</style>
