<template>
  <v-form ref="form">
    <v-row>

      <v-col cols="12">
        <movimiento-expediente-combobox
            v-model="form.nombreMovimiento"
            :etapa-id="etapaId"
            @inputObject="onMovimientoSelected"
        >
        </movimiento-expediente-combobox>
      </v-col>

      <v-col cols="12" v-if="fechaRequerido">
        <date-input
            v-model="form.fecha"
            label="Fecha"
            :rules="required"
        ></date-input>
      </v-col>

      <v-col cols="12" v-if="adjuntoRequerido">
        <media-field
            v-model="form.adjunto"
            fieldName="adjunto"
            label="Adjunto"
            icon="attachments"
            :is-required="adjuntoObligatorio"
        ></media-field>
      </v-col>


      <v-col cols="12" >
        <etapa-expediente-view :etapa-id="form.etapaOrigen" label="Etapa Origen" icon="location_searching"></etapa-expediente-view>
        <etapa-expediente-view :etapa-id="form.etapaDestino" label="Etapa Destino" icon="gps_fixed"></etapa-expediente-view>
      </v-col>

    </v-row>
  </v-form>

</template>

<script>
import MovimientoExpedienteCombobox
  from "@/modules/wolke/components/MovimientoExpedienteCombobox/MovimientoExpedienteCombobox.vue";

import {DateInput} from "@dracul/dayjs-frontend";
import {MediaField} from "@dracul/media-frontend"
import EtapaExpedienteView from "@/modules/wolke/components/EtapaExpedienteView/EtapaExpedienteView.vue";
import {RequiredRule} from "@dracul/common-frontend";

export default {
  name: "ExpedienteMovimientoForm",
  components: {EtapaExpedienteView, MovimientoExpedienteCombobox, DateInput, MediaField},
  props: {
    value: {type: Object, required: true},
    etapaId: {type: String}
  },
  mixins:[ RequiredRule],
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    fechaRequerido() {
      return this.movimientoSelected?.requiereFecha ? true : false
    },
    adjuntoRequerido() {
      return ["SI", "OPCIONAL"].includes(this.movimientoSelected?.requiereAdjunto) ? true : false
    },
    adjuntoObligatorio() {
      return ["SI"].includes(this.movimientoSelected?.requiereAdjunto) ? true : false
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  data(){
    return {
      movimientoSelected: null,
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    resetValidation(){
      this.$refs.form.resetValidation()
      this.movimientoSelected = null
    },
    onMovimientoSelected(movimiento){
      this.movimientoSelected = movimiento;
      this.form.etapaDestino = movimiento?.siguienteEtapa ? movimiento.siguienteEtapa : this.etapaId;
    }
  }
}
</script>

<style scoped>

</style>
