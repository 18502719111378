<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <expediente-form
        ref="form"
        v-model="form"
        :input-errors="inputErrors"
        :movimientos="form.movimientos"
    />
  </crud-create>
</template>

<script>

import ExpedienteProvider from "../../../../providers/ExpedienteProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import ExpedienteForm from "../ExpedienteForm";


export default {
  name: "ExpedienteCreate",

  components: {ExpedienteForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true}
  },

  data() {
    return {
      title: 'wolke.expediente.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        numeroExpediente: '',
        caratula: '',
        montoDemanda: null,
        fechaDemanda: null,
        tribunal: null,
        tipoJuicio: null,
        estado: null,
        provincia: null,
        patrocinante: null,
        cliente: null,
        beneficiario: null,
        personas: [{caracter: null, cuil: null}],
        deudas: [],
        etapa: null,
        movimientos: []
      }
    }
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        ExpedienteProvider.createExpediente(this.form).then(r => {
              if (r) {
                this.$emit('itemCreated', r.data.createExpediente)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }

  },
}
</script>

<style scoped>

</style>

