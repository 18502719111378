
const messages = {
    en: {
       role: {
            permissions: {
                PROVINCIA_MENU: "Access to Provincia",
                PROVINCIA_SHOW: "View Provincia",
                PROVINCIA_CREATE: "Create Provincia",
                PROVINCIA_UPDATE: "Modify Provincia",
                PROVINCIA_DELETE: "Delete Provincia",
            }
       }
    },
    es: {
        role: {
            permissions: {
                PROVINCIA_MENU: "Acceder Provincia",
                PROVINCIA_SHOW: "Ver Provincia",
                PROVINCIA_CREATE: "Crear Provincia",
                PROVINCIA_UPDATE: "Modificar Provincia",
                PROVINCIA_DELETE: "Eliminar Provincia",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                PROVINCIA_MENU: "Acessar Provincia",
                PROVINCIA_SHOW: "Ver Provincia",
                PROVINCIA_CREATE: "Criar Provincia",
                PROVINCIA_UPDATE: "Modificar Provincia",
                PROVINCIA_DELETE: "Eliminar Provincia",
            }
       }
    }
}

export default messages
    