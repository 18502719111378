<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="4">
        <v-text-field

            prepend-icon="short_text"
            name="nombre"
            v-model="form.nombre"
            :label="$t('wolke.patrocinante.labels.nombre')"
            :placeholder="$t('wolke.patrocinante.labels.nombre')"
            :error="hasInputErrors('nombre')"
            :error-messages="getInputErrors('nombre')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="4">
        <v-text-field

            prepend-icon="badge"
            name="DNI"
            v-model="form.DNI"
            :label="$t('wolke.patrocinante.labels.DNI')"
            :placeholder="$t('wolke.patrocinante.labels.DNI')"
            :error="hasInputErrors('DNI')"
            :error-messages="getInputErrors('DNI')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="4">
        <v-text-field

            prepend-icon="verified_user"
            name="matriculaProfesional"
            v-model="form.matriculaProfesional"
            :label="$t('wolke.patrocinante.labels.matriculaProfesional')"
            :placeholder="$t('wolke.patrocinante.labels.matriculaProfesional')"
            :error="hasInputErrors('matriculaProfesional')"
            :error-messages="getInputErrors('matriculaProfesional')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <provincia-combobox v-model="form.provincia" :input-errors="inputErrors"/>
      </v-col>


      <v-col cols="12" sm="6">
        <localidad-combobox v-model="form.localidad" :provincia="form.provincia" :input-errors="inputErrors"/>
      </v-col>

      <v-col cols="12" sm="12">
        <v-text-field

            prepend-icon="location_on"
            name="domicilioConstituido"
            v-model="form.domicilioConstituido"
            :label="$t('wolke.patrocinante.labels.domicilioConstituido')"
            :placeholder="$t('wolke.patrocinante.labels.domicilioConstituido')"
            :error="hasInputErrors('domicilioConstituido')"
            :error-messages="getInputErrors('domicilioConstituido')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <situacion-impositiva-combobox v-model="form.situacionImpositiva" :input-errors="inputErrors"/>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="percent"
            name="IVA"
            v-model.number="form.IVA"
            type="number"
            :label="$t('wolke.patrocinante.labels.IVA')"
            :placeholder="$t('wolke.patrocinante.labels.IVA')"
            :error="hasInputErrors('IVA')"
            :error-messages="getInputErrors('IVA')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="contact_emergency"
            name="CUIT"
            v-model="form.CUIT"
            :label="$t('wolke.patrocinante.labels.CUIT')"
            :placeholder="$t('wolke.patrocinante.labels.CUIT')"
            :error="hasInputErrors('CUIT')"
            :error-messages="getInputErrors('CUIT')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="assignment"
            name="ingresosBrutos"
            v-model="form.ingresosBrutos"
            :label="$t('wolke.patrocinante.labels.ingresosBrutos')"
            :placeholder="$t('wolke.patrocinante.labels.ingresosBrutos')"
            :error="hasInputErrors('ingresosBrutos')"
            :error-messages="getInputErrors('ingresosBrutos')"
            color="secondary"

        ></v-text-field>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import SituacionImpositivaCombobox from "../../../../components/SituacionImpositivaCombobox";
import ProvinciaCombobox from "@/modules/wolke/components/ProvinciaCombobox/ProvinciaCombobox.vue";
import LocalidadCombobox from "@/modules/wolke/components/LocalidadCombobox/LocalidadCombobox.vue";


export default {
  name: "PatrocinanteForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {
    LocalidadCombobox,
    ProvinciaCombobox,
    SituacionImpositivaCombobox,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

