<template>
  <v-autocomplete
      prepend-icon="label"
      :items="items"
      :item-text="'nombre'"
      :item-value="itemValue"
      v-model="item"
      :label="$te(label) ? $t(label) : label"
      :loading="loading"
      :error="hasInputErrors('etapa')"
      :error-messages="getInputErrors('etapa')"
      color="secondary"
      item-color="secondary"
      :rules="isRequired ? required : []"
      :multiple="multiple"
      :chips="chips"
      :solo="solo"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :hide-details="hideDetails"
      :style="{width: width, maxWidth: width}"
      :return-object="returnObject"
  ></v-autocomplete>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import EtapaExpedienteProvider from "../../providers/EtapaExpedienteProvider"

export default {
  name: "EtapaExpedienteCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    provincia: {type: String},
    multiple: {type: Boolean, default: false},
    solo: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    isRequired: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    itemValue: {type: String, default: 'id'},
    width: {type: String, default: null},
    label: {type: String, default: 'wolke.etapaExpediente.name'},
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.fetch()
  },
  watch: {
    provincia: {
      handler() {
        this.$emit('input', null)
        this.fetch()
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetch() {
      this.loading = true

      if (this.provincia) {
        EtapaExpedienteProvider.fetchEtapaExpedienteByProvincia(this.provincia)
            .then(r => {
              this.items = r.data.fetchEtapaExpedienteByProvincia
            })
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
      } else {
        EtapaExpedienteProvider.fetchEtapaExpediente()
            .then(r => {
              this.items = r.data.fetchEtapaExpediente
            })
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
      }


    }

  }
}
</script>

<style scoped>

</style>
