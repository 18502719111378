import graphqlClient from "../../../apollo";

class ExpedienteProvider {

    findExpediente(id) {
        return graphqlClient.query({
            query: require('./gql/Expediente/findExpediente.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchExpedienteByCuil(cuil) {
        return graphqlClient.query({
            query: require('./gql/Expediente/fetchExpedienteByCuil.graphql'),
            variables: {cuil:cuil},
            fetchPolicy: "network-only"
        })
    }

    fetchExpediente() {
        return graphqlClient.query({
        query: require('./gql/Expediente/fetchExpediente.graphql'),
        fetchPolicy: "network-only"
        })
    }

    paginateExpediente(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Expediente/paginateExpediente.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createExpediente(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Expediente/createExpediente.graphql'),
            variables: {input}
        })
    }

    updateExpediente(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Expediente/updateExpediente.graphql'),
            variables: {id, input}
        })
    }

     deleteExpediente(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Expediente/deleteExpediente.graphql'),
            variables: {id}
        })
    }

    addMovimientoExpediente(expedienteId, movimientoInput) {
        return graphqlClient.mutate({
            mutation: require('./gql/Expediente/addMovimientoExpediente.graphql'),
            variables: {expedienteId, movimientoInput}
        })
    }

}

export default new ExpedienteProvider()


