<template>
 <v-row row wrap>

    <v-col cols="12" >
        <v-row justify="space-between">
            <v-col cols="12" sm="6" md="8">
             <!-- FILTERS HERE -->
              <v-row>
                <v-col cols="12" sm="4">
                  <provincia-combobox v-model="filters[0].value" @input="fetch" clearable></provincia-combobox>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols="12" sm="6" md="4">
            <search-input  @search="performSearch" v-model="search" />
            </v-col>
        </v-row>
    </v-col>

    <v-col cols="12">

       <v-data-table
                class="mt-3"
                :headers="headers"
                :items="items"
                :search="search"
                :single-expand="false"
                :server-items-length="totalItems"
                :loading="loading"
                :page.sync="pageNumber"
                :items-per-page.sync="itemsPerPage"
                :sort-by.sync="orderBy"
                :sort-desc.sync="orderDesc"
                :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
                @update:page="fetch"
                @update:sort-by="fetch"
                @update:sort-desc="fetch"
                @update:items-per-page="fetch"
        >


         <template v-slot:item.provincia="{ item }">
            {{ item.provincia ? item.provincia.nombre : '' }}
         </template>




            <template slot="no-data">
               <div class="text-xs-center" v-t="'common.noData'"></div>
            </template>

            <template slot="loading">
               <div   class="text-xs-center" v-t="'common.loading'"></div>
            </template>

            <template v-slot:item.action="{ item }">
                <show-button  @click="$emit('show', item)" />
                <edit-button v-if="$store.getters.hasPermission('ETAPAEXPEDIENTE_UPDATE')"  @click="$emit('update', item)" />
                <delete-button v-if="$store.getters.hasPermission('ETAPAEXPEDIENTE_DELETE')" @click="$emit('delete', item)" />
            </template>

        </v-data-table>
    </v-col>
</v-row>
</template>

<script>
   import EtapaExpedienteProvider from "../../../../providers/EtapaExpedienteProvider";

   import {DeleteButton, EditButton, ShowButton, SearchInput} from "@dracul/common-frontend"
   import ProvinciaCombobox from "@/modules/wolke/components/ProvinciaCombobox/ProvinciaCombobox.vue";


    export default {
        name: "EtapaExpedienteList",
        components: {ProvinciaCombobox, DeleteButton, EditButton, ShowButton, SearchInput},

        data() {
            return {
                items: [],
                totalItems: null,
                loading: false,
                orderBy: null,
                orderDesc: false,
                itemsPerPage: 5,
                pageNumber: 1,
                search: '',
                filters: [
                    {
                        field: 'provincia',
                        operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
                        value: null
                    }
                ]
            }
        },
        computed: {
          headers () {
            return [
                    //Entity Headers
                    {text: this.$t('wolke.etapaExpediente.labels.provincia'), value: 'provincia'},
              {text: this.$t('wolke.etapaExpediente.labels.codigo'), value: 'codigo'},
              {text: this.$t('wolke.etapaExpediente.labels.nombre'), value: 'nombre'},
                    //Actions
                    {text: this.$t('common.actions'), value: 'action', sortable: false},
                ]
          },
          getOrderBy(){
              return  (Array.isArray(this.orderBy)) ? this.orderBy[0]: this.orderBy
          },
          getOrderDesc(){
              return  (Array.isArray(this.orderDesc)) ? this.orderDesc[0]: this.orderDesc
          }
        },
        created() {
            this.fetch()
        },
        methods:{
            performSearch(){
                this.pageNumber = 1
                this.fetch()
            },
            fetch() {
                this.loading = true
                EtapaExpedienteProvider.paginateEtapaExpediente(
                    this.pageNumber,
                    this.itemsPerPage,
                    this.search,
                    this.filters,
                    this.getOrderBy,
                    this.getOrderDesc
                ).then(r => {
                    this.items = r.data.paginateEtapaExpediente.items
                    this.totalItems = r.data.paginateEtapaExpediente.totalItems
                }).catch(err => {
                    console.error(err)
                }).finally(() => this.loading = false)
            }
        }

    }
</script>


