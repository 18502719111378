
const messages = {
    en: {
       wolke: {
          etapaExpediente: {
            name: 'Etapa Procesal',
            title: 'Etapa Procesal management',
            subtitle: 'View, search, create, edit and delete Etapa Procesal',
            creating: 'Creating Etapa Procesal',
            editing: 'Editing Etapa Procesal',
            deleting: 'Deleting Etapa Procesal',
            showing: 'Showing Etapa Procesal',
            menu: 'Etapa Procesal',
            labels: {
                provincia: 'Province',
                nombre: 'Name',
                codigo: 'Code',
                requiereFecha: 'Requiere Fecha',
                requiereAdjunto: 'Requiere Adjunto',
                siguienteEtapa: 'Siguiente Etapa',
            },

          }
       }
    },
    es: {
       wolke: {
          etapaExpediente: {
            name: 'Etapa Procesal',
            title: 'Administración de Etapa Procesal',
            subtitle: 'Ver, buscar, crear, editar, y borrar Etapa Procesal',
            creating: 'Creando Etapa Procesal',
            editing: 'Modificando Etapa Procesal',
            deleting: 'Eliminando Etapa Procesal',
            showing: 'Detalles de Etapa Procesal',
            menu: 'Etapa Procesal',
            labels: {
                provincia: 'Provincia',
                nombre: 'Nombre',
                codigo: 'Código',

                requiereFecha: 'Requiere Fecha',
                requiereAdjunto: 'Requiere Adjunto',
                siguienteEtapa: 'Siguiente Etapa',
            },

          }
       }
    },
    pt: {
       wolke: {
          etapaExpediente: {
            name: 'Etapa Procesal',
            title: 'Administração de Etapa Procesal',
            subtitle: 'Ver, buscar, criar, editar e usar Etapa Procesal',
            creating: 'Criando Etapa Procesal',
            editing: 'Edição Etapa Procesal',
            deleting: 'Apagando Etapa Procesal',
            showing: 'Detalhes do Etapa Procesal',
            menu: 'Etapa Procesal',
            labels: {
                provincia: 'Provincia',
                nombre: 'Nombre',
                codigo: 'Codigo',

                requiereFecha: 'Requiere Fecha',
                requiereAdjunto: 'Requiere Adjunto',
                siguienteEtapa: 'Siguiente Etapa',
            },

          }
       }
    }
}

export default messages
