
const messages = {
    en: {
       wolke: {
          cliente: { 
            name: 'Cliente',
            title: 'Cliente management',
            subtitle: 'View, search, create, edit and delete Cliente',
            creating: 'Creating Cliente',
            editing: 'Editing Cliente',
            deleting: 'Deleting Cliente',
            showing: 'Showing Cliente',
            menu: 'Cliente',
            labels: {
                nombre: 'Cliente',
                cuit: 'CUIT',
                domicilioLegal: 'Domicilio Legal',
                provincia: 'Provincia',
                localidad: 'Localidad',
                situacionImpositiva: 'Situación tributaria',
                cbu: 'CBU'
            },
          
          }
       }
    },
    es: {
       wolke: {
          cliente: { 
            name: 'Cliente',
            title: 'Administración de Cliente',
            subtitle: 'Ver, buscar, crear, editar, y borrar Cliente',
            creating: 'Creando Cliente',
            editing: 'Modificando Cliente',
            deleting: 'Eliminando Cliente',
            showing: 'Detalles de Cliente',
            menu: 'Cliente',
            labels: {
                nombre: 'Cliente',
                cuit: 'CUIT',
                domicilioLegal: 'Domicilio Legal',
                provincia: 'Provincia',
                localidad: 'Localidad',
                situacionImpositiva: 'Situación tributaria',
                cbu: 'CBU'
            },
          
          }
       }
    },
    pt: {
       wolke: {
          cliente: { 
            name: 'Cliente',
            title: 'Administração de Cliente',
            subtitle: 'Ver, buscar, criar, editar e usar Cliente',
            creating: 'Criando Cliente',
            editing: 'Edição Cliente',
            deleting: 'Apagando Cliente',
            showing: 'Detalhes do Cliente',
            menu: 'Cliente',
            labels: {
                nombre: 'Cliente',
                cuit: 'CUIT',
                domicilioLegal: 'Domicilio Legal',
                provincia: 'Provincia',
                localidad: 'Localidad',
                situacionImpositiva: 'Situación tributaria',
                cbu: 'CBU'
            },
          
          }
       }
    }
}

export default messages
    