<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="short_text"
            name="nombre"
            v-model="form.nombre"
            :label="$t('wolke.beneficiario.labels.nombre')"
            :placeholder="$t('wolke.beneficiario.labels.nombre')"
            :error="hasInputErrors('nombre')"
            :error-messages="getInputErrors('nombre')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="badge"
            name="cuit"
            v-model="form.cuit"
            :label="$t('wolke.beneficiario.labels.cuit')"
            :placeholder="$t('wolke.beneficiario.labels.cuit')"
            :error="hasInputErrors('cuit')"
            :error-messages="getInputErrors('cuit')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <provincia-combobox v-model="form.provincia" :input-errors="inputErrors"/>
      </v-col>


      <v-col cols="12" sm="6">
        <localidad-combobox v-model="form.localidad" :provincia="form.provincia" :input-errors="inputErrors"/>
      </v-col>

      <v-col cols="12" sm="12">
        <v-text-field

            prepend-icon="location_on"
            name="domicilioLegal"
            v-model="form.domicilioLegal"
            :label="$t('wolke.beneficiario.labels.domicilioLegal')"
            :placeholder="$t('wolke.beneficiario.labels.domicilioLegal')"
            :error="hasInputErrors('domicilioLegal')"
            :error-messages="getInputErrors('domicilioLegal')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <situacion-impositiva-combobox v-model="form.situacionImpositiva" :input-errors="inputErrors"/>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="account_balance"
            name="cbu"
            v-model="form.cbu"
            :label="$t('wolke.beneficiario.labels.cbu')"
            :placeholder="$t('wolke.beneficiario.labels.cbu')"
            :error="hasInputErrors('cbu')"
            :error-messages="getInputErrors('cbu')"
            color="secondary"

        ></v-text-field>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

import ProvinciaCombobox from "../../../../components/ProvinciaCombobox";
import LocalidadCombobox from "../../../../components/LocalidadCombobox";
import SituacionImpositivaCombobox from "../../../../components/SituacionImpositivaCombobox";


export default {
  name: "BeneficiarioForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {
    ProvinciaCombobox,
    LocalidadCombobox,
    SituacionImpositivaCombobox,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

