<template>
<crud-layout :title="title" :subtitle="subtitle">

        <template v-slot:list>
            <localidad-list 
                       ref="list"
                       @update="update"
                       @delete="remove"
                       @show="show"
            
            />
        </template>
        
         <add-button v-if="$store.getters.hasPermission('LOCALIDAD_CREATE')" @click="create"></add-button>
      
        <localidad-create v-if="creating" 
                        :open="creating"
                        v-on:itemCreated="onItemCreated" 
                        v-on:close="creating=false" 
        />
        
        <localidad-update v-if="updating" 
                        :open="updating"
                        :item="itemToEdit" 
                        v-on:itemUpdated="onItemUpdated" 
                        v-on:close="updating=false" 
        />
          
        <localidad-show v-if="showing" 
                           :open="showing" 
                           :item="itemToShow"  
                           v-on:close="showing=false" 
         />

        <localidad-delete v-if="deleting" 
                         :open="deleting"
                         :item="itemToDelete"  
                         v-on:itemDeleted="onItemDeleted" 
                         v-on:close="deleting=false" 
        />

        <snackbar v-model="flash"/>

</crud-layout>
</template>

<script>
    
    import LocalidadCreate from "./LocalidadCreate";
    import LocalidadUpdate from "./LocalidadUpdate";
    import LocalidadDelete from "./LocalidadDelete";
    import LocalidadShow from "./LocalidadShow";
    import LocalidadList from "./LocalidadList";
    
     import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"
     
    export default {
        name: "LocalidadCrudPage",
        components: {
            CrudLayout, AddButton, Snackbar,
            LocalidadCreate, 
            LocalidadUpdate, 
            LocalidadDelete, 
            LocalidadShow,
            LocalidadList
        },
        data() {
            return {
                title: 'wolke.localidad.title',
                subtitle: 'wolke.localidad.subtitle',
                flash: null,
                creating: false,
                updating: false,
                deleting: false,
                showing: false,
                itemToEdit: null,
                itemToDelete: null,
                itemToShow: null,
            }
        },
        methods: {
            //On
            onItemCreated() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.created")
            },
            onItemUpdated() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.updated")
            },
            onItemDeleted() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.deleted")
            },
            //Open
            create() {
                this.creating = true
            },
            update(item) {
                this.updating = true
                this.itemToEdit = item
            },
            show(item) {
                this.showing = true
                this.itemToShow = item
            },
            remove(item) {
                this.deleting = true
                this.itemToDelete = item
            }
        }
        
    }
</script>


