<template>

  <v-select
      prepend-icon="gamepad"
      :items="items"
      v-model="item"
      :label="$t('wolke.etapaExpediente.labels.requiereAdjunto')"
      :loading="loading"
      :error="hasInputErrors('requiereAdjunto')"
      :error-messages="getInputErrors('requiereAdjunto')"
      color="secondary"
      item-color="secondary"
      :rules="required"
      :clearable="clearable"
      :multiple="multiple"
      :chips="chips"
      :solo="solo"
  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'



export default {
  name: "MovimientoRequiereAdjuntoCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props:{
    value: {type: [String, Array]},
    clearable: {type:Boolean, default: false},
    multiple: {type:Boolean, default: false},
    solo: {type:Boolean, default: false},
    chips: {type:Boolean, default: false}
  },
  data() {
    return {
      items: ['NO','SI','OPCIONAL'],
      loading: false
    }
  },
  computed: {
    item: {
      get() { return this.value },
      set(val) {this.$emit('input', val)}
    }
  },
  methods: {
    validate(){
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>

