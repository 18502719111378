
const messages = {
    en: {
       role: {
            permissions: {
                BENEFICIARIO_MENU: "Access to Beneficiario",
                BENEFICIARIO_SHOW: "View Beneficiario",
                BENEFICIARIO_CREATE: "Create Beneficiario",
                BENEFICIARIO_UPDATE: "Modify Beneficiario",
                BENEFICIARIO_DELETE: "Delete Beneficiario",
            }
       }
    },
    es: {
        role: {
            permissions: {
                BENEFICIARIO_MENU: "Acceder Beneficiario",
                BENEFICIARIO_SHOW: "Ver Beneficiario",
                BENEFICIARIO_CREATE: "Crear Beneficiario",
                BENEFICIARIO_UPDATE: "Modificar Beneficiario",
                BENEFICIARIO_DELETE: "Eliminar Beneficiario",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                BENEFICIARIO_MENU: "Acessar Beneficiario",
                BENEFICIARIO_SHOW: "Ver Beneficiario",
                BENEFICIARIO_CREATE: "Criar Beneficiario",
                BENEFICIARIO_UPDATE: "Modificar Beneficiario",
                BENEFICIARIO_DELETE: "Eliminar Beneficiario",
            }
       }
    }
}

export default messages
    