
const messages = {
    en: {
       wolke: {
          expediente: {
            name: 'Expediente',
            title: 'Expediente management',
            subtitle: 'View, search, create, edit and delete Expediente',
            creating: 'Creating Expediente',
            editing: 'Editing Expediente',
            deleting: 'Deleting Expediente',
            showing: 'Showing Expediente',
            menu: 'Expediente',
            labels: {
                numeroExpediente: 'N° Exp',
                caratula: 'Carátula',
                montoDemanda: 'Monto Demanda',
                fechaDemanda: 'Fecha Demanda',
                tribunal: 'Tribunal',
                tipoJuicio: 'Tipo Juicio',
                estado: 'Estado',
                provincia: 'Provincia',
                patrocinante: 'Patrocinante',
                cliente: 'Cliente',
                beneficiario: 'Beneficiario',
                caracter: 'Caracter',
                cuil: 'Cuil',
                movimiento: 'Movement',
                etapa: 'Stage'
            },

          }
       }
    },
    es: {
       wolke: {
          expediente: {
            name: 'Expediente',
            title: 'Administración de Expediente',
            subtitle: 'Ver, buscar, crear, editar, y borrar Expediente',
            creating: 'Creando Expediente',
            editing: 'Modificando Expediente',
            deleting: 'Eliminando Expediente',
            showing: 'Detalles de Expediente',
            menu: 'Expediente',
            labels: {
                numeroExpediente: 'N° Exp',
                caratula: 'Carátula',
                montoDemanda: 'Monto Demanda',
                fechaDemanda: 'Fecha Demanda',
                tribunal: 'Tribunal',
                tipoJuicio: 'Tipo Juicio',
                estado: 'Estado',
                provincia: 'Provincia',
                patrocinante: 'Patrocinante',
                cliente: 'Cliente',
                beneficiario: 'Beneficiario',
                caracter: 'Caracter',
                cuil: 'Cuil',
                movimiento: 'Movimento',
                etapa: 'Etapa'
            },

          }
       }
    },
    pt: {
       wolke: {
          expediente: {
            name: 'Expediente',
            title: 'Administração de Expediente',
            subtitle: 'Ver, buscar, criar, editar e usar Expediente',
            creating: 'Criando Expediente',
            editing: 'Edição Expediente',
            deleting: 'Apagando Expediente',
            showing: 'Detalhes do Expediente',
            menu: 'Expediente',
            labels: {
                numeroExpediente: 'N° Exp',
                caratula: 'Carátula',
                montoDemanda: 'Monto Demanda',
                fechaDemanda: 'Fecha Demanda',
                tribunal: 'Tribunal',
                tipoJuicio: 'Tipo Juicio',
                estado: 'Estado',
                provincia: 'Provincia',
                patrocinante: 'Patrocinante',
                cliente: 'Cliente',
                beneficiario: 'Beneficiario',
                caracter: 'Caracter',
                cuil: 'Cuil',
                movimiento: 'Movimento',
                etapa: 'Etapa'
            },

          }
       }
    }
}

export default messages
