import graphqlClient from "../../../apollo";

class ProvinciaProvider {

    findProvincia(id) {
        return graphqlClient.query({
            query: require('./gql/Provincia/findProvincia.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchProvincia() {
        return graphqlClient.query({
        query: require('./gql/Provincia/fetchProvincia.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginateProvincia(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Provincia/paginateProvincia.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createProvincia(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Provincia/createProvincia.graphql'),
            variables: {input}
        })
    }
    
    updateProvincia(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Provincia/updateProvincia.graphql'),
            variables: {id, input}
        })
    }
    
     deleteProvincia(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Provincia/deleteProvincia.graphql'),
            variables: {id}
        })
    }

}

export default new ProvinciaProvider()


