import { render, staticRenderFns } from "./PatrocinanteCrudPage.vue?vue&type=template&id=205f90a3"
import script from "./PatrocinanteCrudPage.vue?vue&type=script&lang=js"
export * from "./PatrocinanteCrudPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports