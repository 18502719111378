<template>
  <span v-if="raw">{{nombre}}</span>
  <show-field v-else :value="nombre" :label="label" :icon="icon"></show-field>
</template>

<script>
import EtapaExpedienteProvider from "@/modules/wolke/providers/EtapaExpedienteProvider";
import {ShowField} from "@dracul/common-frontend"
export default {
  name: "EtapaExpedienteView",
  components:{ShowField},
  props:{
    etapaId: {type:String},
    raw: {type:Boolean, default:false},
    label: {type:String, default:"Etapa"},
    icon: {type:String, default:"label"}
  },
  data() {
    return {
      etapa: null
    }
  },
  mounted() {
    this.fetch()
  },
  computed:{
    nombre() {
      return this.etapa?.nombre
    }
  },
  watch:{
    etapaId() {
      this.fetch()
    }
  },
  methods: {
    fetch() {
      this.loading = true

      if (this.etapaId) {
        EtapaExpedienteProvider.findEtapaExpediente(this.etapaId)
            .then(r => {
              this.etapa = r.data.findEtapaExpediente
            })
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
      }
    }
  }
}
</script>

<style scoped>

</style>
