
const messages = {
    en: {
       role: {
            permissions: {
                PATROCINANTE_MENU: "Access to Patrocinante",
                PATROCINANTE_SHOW: "View Patrocinante",
                PATROCINANTE_CREATE: "Create Patrocinante",
                PATROCINANTE_UPDATE: "Modify Patrocinante",
                PATROCINANTE_DELETE: "Delete Patrocinante",
            }
       }
    },
    es: {
        role: {
            permissions: {
                PATROCINANTE_MENU: "Acceder Patrocinante",
                PATROCINANTE_SHOW: "Ver Patrocinante",
                PATROCINANTE_CREATE: "Crear Patrocinante",
                PATROCINANTE_UPDATE: "Modificar Patrocinante",
                PATROCINANTE_DELETE: "Eliminar Patrocinante",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                PATROCINANTE_MENU: "Acessar Patrocinante",
                PATROCINANTE_SHOW: "Ver Patrocinante",
                PATROCINANTE_CREATE: "Criar Patrocinante",
                PATROCINANTE_UPDATE: "Modificar Patrocinante",
                PATROCINANTE_DELETE: "Eliminar Patrocinante",
            }
       }
    }
}

export default messages
    