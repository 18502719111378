
const messages = {
    en: {
       wolke: {
          patrocinante: { 
            name: 'Patrocinante',
            title: 'Patrocinante management',
            subtitle: 'View, search, create, edit and delete Patrocinante',
            creating: 'Creating Patrocinante',
            editing: 'Editing Patrocinante',
            deleting: 'Deleting Patrocinante',
            showing: 'Showing Patrocinante',
            menu: 'Patrocinante',
            labels: {
                nombre: 'Nombre',
                DNI: 'DNI',
                matriculaProfesional: 'Matricula Profesional',
                domicilioConstituido: 'Domicilio constituído',
                localidad: 'Localidad',
                provincia: 'Provincia',
                situacionImpositiva: 'Situación Impositiva',
                IVA: 'IVA',
                CUIT: 'CUIT',
                ingresosBrutos: 'Ingresos Brutos'
            },
          
          }
       }
    },
    es: {
       wolke: {
          patrocinante: { 
            name: 'Patrocinante',
            title: 'Administración de Patrocinante',
            subtitle: 'Ver, buscar, crear, editar, y borrar Patrocinante',
            creating: 'Creando Patrocinante',
            editing: 'Modificando Patrocinante',
            deleting: 'Eliminando Patrocinante',
            showing: 'Detalles de Patrocinante',
            menu: 'Patrocinante',
            labels: {
                nombre: 'Nombre',
                DNI: 'DNI',
                matriculaProfesional: 'Matricula Profesional',
                domicilioConstituido: 'Domicilio constituído',
                localidad: 'Localidad',
                provincia: 'Provincia',
                situacionImpositiva: 'Situación Impositiva',
                IVA: 'IVA',
                CUIT: 'CUIT',
                ingresosBrutos: 'Ingresos Brutos'
            },
          
          }
       }
    },
    pt: {
       wolke: {
          patrocinante: { 
            name: 'Patrocinante',
            title: 'Administração de Patrocinante',
            subtitle: 'Ver, buscar, criar, editar e usar Patrocinante',
            creating: 'Criando Patrocinante',
            editing: 'Edição Patrocinante',
            deleting: 'Apagando Patrocinante',
            showing: 'Detalhes do Patrocinante',
            menu: 'Patrocinante',
            labels: {
                nombre: 'Nombre',
                DNI: 'DNI',
                matriculaProfesional: 'Matricula Profesional',
                domicilioConstituido: 'Domicilio constituído',
                localidad: 'Localidad',
                provincia: 'Provincia',
                situacionImpositiva: 'Situación Impositiva',
                IVA: 'IVA',
                CUIT: 'CUIT',
                ingresosBrutos: 'Ingresos Brutos'
            },
          
          }
       }
    }
}

export default messages
    