
const messages = {
    en: {
       wolke: {
          beneficiario: { 
            name: 'Beneficiario',
            title: 'Beneficiario management',
            subtitle: 'View, search, create, edit and delete Beneficiario',
            creating: 'Creating Beneficiario',
            editing: 'Editing Beneficiario',
            deleting: 'Deleting Beneficiario',
            showing: 'Showing Beneficiario',
            menu: 'Beneficiario',
            labels: {
                nombre: 'Cliente',
                cuit: 'CUIT',
                domicilioLegal: 'Domicilio Legal',
                provincia: 'Provincia',
                localidad: 'Localidad',
                situacionImpositiva: 'Situación tributaria',
                cbu: 'CBU'
            },
          
          }
       }
    },
    es: {
       wolke: {
          beneficiario: { 
            name: 'Beneficiario',
            title: 'Administración de Beneficiario',
            subtitle: 'Ver, buscar, crear, editar, y borrar Beneficiario',
            creating: 'Creando Beneficiario',
            editing: 'Modificando Beneficiario',
            deleting: 'Eliminando Beneficiario',
            showing: 'Detalles de Beneficiario',
            menu: 'Beneficiario',
            labels: {
                nombre: 'Cliente',
                cuit: 'CUIT',
                domicilioLegal: 'Domicilio Legal',
                provincia: 'Provincia',
                localidad: 'Localidad',
                situacionImpositiva: 'Situación tributaria',
                cbu: 'CBU'
            },
          
          }
       }
    },
    pt: {
       wolke: {
          beneficiario: { 
            name: 'Beneficiario',
            title: 'Administração de Beneficiario',
            subtitle: 'Ver, buscar, criar, editar e usar Beneficiario',
            creating: 'Criando Beneficiario',
            editing: 'Edição Beneficiario',
            deleting: 'Apagando Beneficiario',
            showing: 'Detalhes do Beneficiario',
            menu: 'Beneficiario',
            labels: {
                nombre: 'Cliente',
                cuit: 'CUIT',
                domicilioLegal: 'Domicilio Legal',
                provincia: 'Provincia',
                localidad: 'Localidad',
                situacionImpositiva: 'Situación tributaria',
                cbu: 'CBU'
            },
          
          }
       }
    }
}

export default messages
    