<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="4">
              <show-field :value="item.numeroExpediente" :label="$t('wolke.expediente.labels.numeroExpediente')" icon="tag"/>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <show-field :value="item.caratula" :label="$t('wolke.expediente.labels.caratula')" icon="insert_drive_file"/>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <show-field :value="String(item.montoDemanda)" :label="$t('wolke.expediente.labels.montoDemanda')" icon="paid"/>

          </v-col>

          <v-col cols="12" sm="4" md="4">
            <show-field :value="getDateFormat(item.fechaDemanda)" :label="$t('wolke.expediente.labels.fechaDemanda')" icon="event"/>

          </v-col>

          <v-col cols="12" sm="4" md="4">
            <show-field :value="item.estado.nombre" :label="$t('wolke.expediente.labels.estado')" icon="playlist_add_check_circle"/>

          </v-col>

          <v-col cols="12" sm="4" md="4">
            <show-field :value="item.tribunal.nombre" :label="$t('wolke.expediente.labels.tribunal')" icon="gavel"/>

          </v-col>

          <v-col cols="12" sm="4" md="4">
            <show-field :value="item.tipoJuicio.nombre" :label="$t('wolke.expediente.labels.tipoJuicio')" icon="read_more"/>

          </v-col>

          <v-col cols="12" sm="4" md="4">
            <show-field :value="item.provincia.nombre" :label="$t('wolke.expediente.labels.provincia')" icon="add_location_alt"/>

          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <v-card outlined class="mt-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <show-field :value="item.cliente.nombre" :label="$t('wolke.expediente.labels.cliente')" icon="contact_page"/>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <show-field :value="item.beneficiario.nombre" :label="$t('wolke.expediente.labels.beneficiario')" icon="group_add"/>

          </v-col>
          <v-col cols="12" sm="4" md="4">
            <show-field :value="item.patrocinante.nombre" :label="$t('wolke.expediente.labels.patrocinante')" icon="sensor_occupied"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="mt-2">
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-title>Personas</v-card-title>
          <v-card-text>
            <ul>
              <li v-for="persona in item.personas" :key="persona.cuil">
                {{persona.caracter}}: {{persona.cuil}}
              </li>
            </ul>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-title>Deudas</v-card-title>
          <v-card-text>
            <ul>
              <li v-for="deuda in item.deudas" :key="deuda">
                {{deuda}}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
    import {DayjsMixin} from "@dracul/dayjs-frontend";

    export default {
        name: 'ExpedienteShowData',
        components: {ShowField},
        mixins: [DayjsMixin],
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

