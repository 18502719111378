import TribunalCrudPage from '../pages/crud/TribunalCrudPage'
import PatrocinanteCrudPage from '../pages/crud/PatrocinanteCrudPage'
import TipoJuicioCrudPage from '../pages/crud/TipoJuicioCrudPage'
import EstadoExpedienteCrudPage from '../pages/crud/EstadoExpedienteCrudPage'
import ProvinciaCrudPage from '../pages/crud/ProvinciaCrudPage'
import LocalidadCrudPage from '../pages/crud/LocalidadCrudPage'
import ClienteCrudPage from '../pages/crud/ClienteCrudPage'
import BeneficiarioCrudPage from '../pages/crud/BeneficiarioCrudPage'
import ExpedienteCrudPage from '../pages/crud/ExpedienteCrudPage'
import EtapaExpedienteCrudPage from '../pages/crud/EtapaExpedienteCrudPage'

const routes = [
       
     {
        name: 'TribunalCrudPage', 
        path: '/crud/tribunal', 
        component: TribunalCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "TRIBUNAL_MENU"
        }
     },
   
     {
        name: 'PatrocinanteCrudPage', 
        path: '/crud/patrocinante', 
        component: PatrocinanteCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "PATROCINANTE_MENU"
        }
     },
   
     {
        name: 'TipoJuicioCrudPage', 
        path: '/crud/tipojuicio', 
        component: TipoJuicioCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "TIPOJUICIO_MENU"
        }
     },
   
     {
        name: 'EstadoExpedienteCrudPage', 
        path: '/crud/estadoexpediente', 
        component: EstadoExpedienteCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "ESTADOEXPEDIENTE_MENU"
        }
     },
   
     {
        name: 'ProvinciaCrudPage', 
        path: '/crud/provincia', 
        component: ProvinciaCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "PROVINCIA_MENU"
        }
     },
   
     {
        name: 'LocalidadCrudPage', 
        path: '/crud/localidad', 
        component: LocalidadCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "LOCALIDAD_MENU"
        }
     },
   
     {
        name: 'ClienteCrudPage', 
        path: '/crud/cliente', 
        component: ClienteCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "CLIENTE_MENU"
        }
     },
   
     {
        name: 'BeneficiarioCrudPage', 
        path: '/crud/beneficiario', 
        component: BeneficiarioCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "BENEFICIARIO_MENU"
        }
     },
   
     {
        name: 'ExpedienteCrudPage', 
        path: '/crud/expediente', 
        component: ExpedienteCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "EXPEDIENTE_MENU"
        }
     },
   
     {
        name: 'EtapaExpedienteCrudPage', 
        path: '/crud/etapaexpediente', 
        component: EtapaExpedienteCrudPage,  
        meta: {
            requiresAuth: true,
            permission: "ETAPAEXPEDIENTE_MENU"
        }
     }
]

export default routes;
