<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.nombre" :label="$t('wolke.patrocinante.labels.nombre')" icon="short_text"/>
                 <show-field :value="item.domicilioConstituido" :label="$t('wolke.patrocinante.labels.domicilioConstituido')" icon="location_on"/>
                 <show-field :value="item.situacionImpositiva" :label="$t('wolke.patrocinante.labels.situacionImpositiva')" icon="report_gmailerrorred"/>
                 <show-field :value="item.ingresosBrutos" :label="$t('wolke.patrocinante.labels.ingresosBrutos')" icon="assignment"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.DNI" :label="$t('wolke.patrocinante.labels.DNI')" icon="badge"/>
                 <show-field :value="item.localidad" :label="$t('wolke.patrocinante.labels.localidad')" icon="pin_drop"/>
                 <show-field :value="String(item.IVA)" :label="$t('wolke.patrocinante.labels.IVA')" icon="percent"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.matriculaProfesional" :label="$t('wolke.patrocinante.labels.matriculaProfesional')" icon="verified_user"/>
                 <show-field :value="item.provincia" :label="$t('wolke.patrocinante.labels.provincia')" icon="map"/>
                 <show-field :value="item.CUIT" :label="$t('wolke.patrocinante.labels.CUIT')" icon="contact_emergency"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
     
    
    export default {
        name: 'PatrocinanteShowData',
        components: {ShowField},
        
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

