<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">

    <v-card outlined>
      <v-card-title>Etapa: <span class="primary--text ml-1"> {{form.nombre}}</span> </v-card-title>
      <v-card-text>
        <v-row>

          <v-col cols="12" sm="6">
            <provincia-combobox v-model="form.provincia" :input-errors="inputErrors"/>
          </v-col>


          <v-col cols="12" sm="6">
            <v-text-field

                prepend-icon="label"
                name="nombre"
                v-model="form.nombre"
                :label="$t('wolke.etapaExpediente.labels.nombre')"
                :placeholder="$t('wolke.etapaExpediente.labels.nombre')"
                :error="hasInputErrors('nombre')"
                :error-messages="getInputErrors('nombre')"
                color="secondary"
                :rules="required"
            ></v-text-field>
          </v-col>


          <v-col cols="12" sm="6">
            <v-text-field

                prepend-icon="label"
                name="codigo"
                v-model="form.codigo"
                :label="$t('wolke.etapaExpediente.labels.codigo')"
                :placeholder="$t('wolke.etapaExpediente.labels.codigo')"
                :error="hasInputErrors('codigo')"
                :error-messages="getInputErrors('codigo')"
                color="secondary"
                :rules="required"
            ></v-text-field>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <v-card outlined>
      <v-card-title>Movimientos</v-card-title>
      <v-card-text>
        <form-list
            :new-item="{codigo: null, nombre: null, requiereFecha: false, requiereAdjunto: 'NO', siguienteEtapa: null}"
            v-model="form.movimientos"
            :min-length="1"
        >
          <template v-slot:default="{item,index}">
            <movimiento-etapa-expediente-form
                v-model="form.movimientos[index]"
                :provincia="form.provincia"
                :key="index"
            ></movimiento-etapa-expediente-form>
          </template>
        </form-list>

      </v-card-text>
    </v-card>


  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule, FormList} from '@dracul/common-frontend'

import ProvinciaCombobox from "../../../../components/ProvinciaCombobox";
import MovimientoEtapaExpedienteForm
  from "@/modules/wolke/pages/crud/EtapaExpedienteCrudPage/EtapaExpedienteForm/MovimientoEtapaExpedienteForm/MovimientoEtapaExpedienteForm.vue";


export default {
  name: "EtapaExpedienteForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {
    MovimientoEtapaExpedienteForm,
    ProvinciaCombobox,
    FormList
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

