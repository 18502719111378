<template>
  <v-row>

    <v-col cols="12" sm="6">
      <v-text-field
          prepend-icon="source"
          name="caracter"
          v-model="form.caracter"
          :label="$t('wolke.expediente.labels.caracter')"
          :placeholder="$t('wolke.expediente.labels.caracter')"
          :error="hasInputErrors('caracter')"
          :error-messages="getInputErrors('caracter')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>



    <v-col cols="12" sm="6">
      <v-text-field
          prepend-icon="badge"
          name="cuil"
          v-model="form.cuil"
          :label="$t('wolke.expediente.labels.cuil')"
          :placeholder="$t('wolke.expediente.labels.cuil')"
          :error="hasInputErrors('cuil')"
          :error-messages="getInputErrors('cuil')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

export default {
  name: "ExpedientePersonaForm",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {type: Boolean, default:false}
  },
  data(){
    return {
      search: ''
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
