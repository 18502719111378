
const messages = {
    en: {
       wolke: {
          tribunal: { 
            name: 'Tribunal',
            title: 'Tribunal management',
            subtitle: 'View, search, create, edit and delete Tribunal',
            creating: 'Creating Tribunal',
            editing: 'Editing Tribunal',
            deleting: 'Deleting Tribunal',
            showing: 'Showing Tribunal',
            menu: 'Tribunal',
            labels: {
                nombre: 'Nombre',
                secretaria: 'Secretaría',
                provincia: 'Provincia',
                localidad: 'Localidad',
                domicilio: 'Domicilio',
                email: 'Correo electrónico'
            },
          
          }
       }
    },
    es: {
       wolke: {
          tribunal: { 
            name: 'Tribunal',
            title: 'Administración de Tribunal',
            subtitle: 'Ver, buscar, crear, editar, y borrar Tribunal',
            creating: 'Creando Tribunal',
            editing: 'Modificando Tribunal',
            deleting: 'Eliminando Tribunal',
            showing: 'Detalles de Tribunal',
            menu: 'Tribunal',
            labels: {
                nombre: 'Nombre',
                secretaria: 'Secretaría',
                provincia: 'Provincia',
                localidad: 'Localidad',
                domicilio: 'Domicilio',
                email: 'Correo electrónico'
            },
          
          }
       }
    },
    pt: {
       wolke: {
          tribunal: { 
            name: 'Tribunal',
            title: 'Administração de Tribunal',
            subtitle: 'Ver, buscar, criar, editar e usar Tribunal',
            creating: 'Criando Tribunal',
            editing: 'Edição Tribunal',
            deleting: 'Apagando Tribunal',
            showing: 'Detalhes do Tribunal',
            menu: 'Tribunal',
            labels: {
                nombre: 'Nombre',
                secretaria: 'Secretaría',
                provincia: 'Provincia',
                localidad: 'Localidad',
                domicilio: 'Domicilio',
                email: 'Correo electrónico'
            },
          
          }
       }
    }
}

export default messages
    