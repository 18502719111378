<template>
        <crud-update :open="open"
                 :loading="loading"
                 :title="title"
                 :errorMessage="errorMessage"
                 @update="update"
                 @close="$emit('close')"
                 fullscreen
    >
         <etapa-expediente-form ref="form" v-model="form" :input-errors="inputErrors" />
    </crud-update>
</template>

<script>

    import EtapaExpedienteProvider from "../../../../providers/EtapaExpedienteProvider";

    import {CrudUpdate, ClientError} from '@dracul/common-frontend'

    import EtapaExpedienteForm from "../EtapaExpedienteForm";



    export default {
        name: "EtapaExpedienteUpdate",

        components: { EtapaExpedienteForm, CrudUpdate },

        props:{
          open: {type: Boolean, default: true},
          item: {type: Object, required: true}
        },

        data() {
            return {
                title: 'wolke.etapaExpediente.editing',
                errorMessage: '',
                inputErrors: {},
                loading: false,
                id: this.item.id,
                form: {
                    provincia: this.item.provincia ? this.item.provincia.id : null,
                    nombre: this.item.nombre,
                    codigo: this.item.codigo,
                  movimientos: this.item.movimientos ? this.item.movimientos : [],
                }
            }
        },
        methods: {
            update() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    EtapaExpedienteProvider.updateEtapaExpediente(this.id, this.form).then(r => {
                            if (r) {
                                this.$emit('itemUpdated',r.data.updateEtapaExpediente)
                                this.$emit('close')
                            }
                        }
                    ).catch(error => {
                         let clientError = new ClientError(error)
                         this.inputErrors = clientError.inputErrors
                         this.errorMessage = clientError.i18nMessage
                    }).finally(() => this.loading = false)
                }

            }
        },
    }
</script>

<style scoped>

</style>

