<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <etapa-expediente-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-create>
</template>

<script>

import EtapaExpedienteProvider from "../../../../providers/EtapaExpedienteProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import EtapaExpedienteForm from "../EtapaExpedienteForm";


export default {
  name: "EtapaExpedienteCreate",

  components: {EtapaExpedienteForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true}
  },

  data() {
    return {
      title: 'wolke.etapaExpediente.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        provincia: null,
        nombre: '',
        codigo: '',
        movimientos: []
      }
    }
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        EtapaExpedienteProvider.createEtapaExpediente(this.form).then(r => {
              if (r) {
                this.$emit('itemCreated', r.data.createEtapaExpediente)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }

  },
}
</script>

<style scoped>

</style>

