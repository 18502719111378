import { render, staticRenderFns } from "./EstadoExpedienteCombobox.vue?vue&type=template&id=063d34f9&scoped=true"
import script from "./EstadoExpedienteCombobox.vue?vue&type=script&lang=js"
export * from "./EstadoExpedienteCombobox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063d34f9",
  null
  
)

export default component.exports