
const messages = {
    en: {
       wolke: {
          localidad: { 
            name: 'Localidad',
            title: 'Localidad management',
            subtitle: 'View, search, create, edit and delete Localidad',
            creating: 'Creating Localidad',
            editing: 'Editing Localidad',
            deleting: 'Deleting Localidad',
            showing: 'Showing Localidad',
            menu: 'Localidad',
            labels: {
                nombre: 'Localidad',
                provincia: 'Provincia'
            },
          
          }
       }
    },
    es: {
       wolke: {
          localidad: { 
            name: 'Localidad',
            title: 'Administración de Localidad',
            subtitle: 'Ver, buscar, crear, editar, y borrar Localidad',
            creating: 'Creando Localidad',
            editing: 'Modificando Localidad',
            deleting: 'Eliminando Localidad',
            showing: 'Detalles de Localidad',
            menu: 'Localidad',
            labels: {
                nombre: 'Localidad',
                provincia: 'Provincia'
            },
          
          }
       }
    },
    pt: {
       wolke: {
          localidad: { 
            name: 'Localidad',
            title: 'Administração de Localidad',
            subtitle: 'Ver, buscar, criar, editar e usar Localidad',
            creating: 'Criando Localidad',
            editing: 'Edição Localidad',
            deleting: 'Apagando Localidad',
            showing: 'Detalhes do Localidad',
            menu: 'Localidad',
            labels: {
                nombre: 'Localidad',
                provincia: 'Provincia'
            },
          
          }
       }
    }
}

export default messages
    