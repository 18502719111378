
const messages = {
    en: {
       role: {
            permissions: {
                ETAPAEXPEDIENTE_MENU: "Access to Etapa Expediente",
                ETAPAEXPEDIENTE_SHOW: "View Etapa Expediente",
                ETAPAEXPEDIENTE_CREATE: "Create Etapa Expediente",
                ETAPAEXPEDIENTE_UPDATE: "Modify Etapa Expediente",
                ETAPAEXPEDIENTE_DELETE: "Delete Etapa Expediente",
            }
       }
    },
    es: {
        role: {
            permissions: {
                ETAPAEXPEDIENTE_MENU: "Acceder Etapa Expediente",
                ETAPAEXPEDIENTE_SHOW: "Ver Etapa Expediente",
                ETAPAEXPEDIENTE_CREATE: "Crear Etapa Expediente",
                ETAPAEXPEDIENTE_UPDATE: "Modificar Etapa Expediente",
                ETAPAEXPEDIENTE_DELETE: "Eliminar Etapa Expediente",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                ETAPAEXPEDIENTE_MENU: "Acessar EtapaExpediente",
                ETAPAEXPEDIENTE_SHOW: "Ver EtapaExpediente",
                ETAPAEXPEDIENTE_CREATE: "Criar EtapaExpediente",
                ETAPAEXPEDIENTE_UPDATE: "Modificar EtapaExpediente",
                ETAPAEXPEDIENTE_DELETE: "Eliminar EtapaExpediente",
            }
       }
    }
}

export default messages
