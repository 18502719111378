<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
  >
    <tribunal-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-update>
</template>

<script>

import TribunalProvider from "../../../../providers/TribunalProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import TribunalForm from "../TribunalForm";


export default {
  name: "TribunalUpdate",

  components: {TribunalForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'wolke.tribunal.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        nombre: this.item.nombre,
        secretaria: this.item.secretaria,
        provincia: this.item.provincia ? this.item.provincia.id : null,
        localidad: this.item.localidad ? this.item.localidad.id : null,
        domicilio: this.item.domicilio,
        email: this.item.email
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true
        TribunalProvider.updateTribunal(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updateTribunal)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

