<template>
  <v-row row wrap>

    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="12" sm="8" md="8">
          <!-- FILTERS HERE -->
          <expediente-filters v-model="filters" @update="fetch"></expediente-filters>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <search-input @search="performSearch" v-model="search" label="Buscar número o carátula" />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          class="mt-3"
          :headers="headers"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >


        <template v-slot:item.tribunal="{ item }">
          {{ item.tribunal ? item.tribunal.nombre : '' }}
        </template>


        <template v-slot:item.tipoJuicio="{ item }">
          {{ item.tipoJuicio ? item.tipoJuicio.nombre : '' }}
        </template>


        <template v-slot:item.estado="{ item }">
          {{ item.estado ? item.estado.nombre : '' }}
        </template>


        <template v-slot:item.provincia="{ item }">
          {{ item.provincia ? item.provincia.nombre : '' }}
        </template>


        <template v-slot:item.patrocinante="{ item }">
          {{ item.patrocinante ? item.patrocinante.nombre : '' }}
        </template>


        <template v-slot:item.cliente="{ item }">
          {{ item.cliente ? item.cliente.nombre : '' }}
        </template>


        <template v-slot:item.beneficiario="{ item }">
          {{ item.beneficiario ? item.beneficiario.nombre : '' }}
        </template>


        <template v-slot:item.etapa="{ item }">
          {{ item.etapa ? item.etapa.nombre : '' }}
        </template>

        <template v-slot:item.fechaDemanda="{ item }">
          {{ getDateFormat(item.fechaDemanda) }}
        </template>


        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.action="{ item }">
          <show-button @click="$emit('show', item)"/>
          <edit-button v-if="$store.getters.hasPermission('EXPEDIENTE_UPDATE')" @click="$emit('update', item)"/>
          <delete-button v-if="$store.getters.hasPermission('EXPEDIENTE_DELETE')" @click="$emit('delete', item)"/>
        </template>

      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ExpedienteProvider from "../../../../providers/ExpedienteProvider";

import {DeleteButton, EditButton, ShowButton, SearchInput} from "@dracul/common-frontend"
import {DayjsMixin} from "@dracul/dayjs-frontend";
import ExpedienteFilters from "../ExpedienteFilters"

export default {
  name: "ExpedienteList",
  components: {ExpedienteFilters, DeleteButton, EditButton, ShowButton, SearchInput},
  mixins: [DayjsMixin],
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 5,
      pageNumber: 1,
      search: '',
      filters: [
        {
          field: 'provincia',
          operator: 'in', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        },
        {
          field: 'estado',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        },
        {
          field: 'etapa',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        },
        {
          field: 'patrocinante',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        },
        {
          field: 'cliente',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        },
        {
          field: 'beneficiario',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        }
      ]
    }
  },
  computed: {
    headers() {
      return [
        //Entity Headers
        {text: this.$t('wolke.expediente.labels.numeroExpediente'), value: 'numeroExpediente'},
        {text: this.$t('wolke.expediente.labels.caratula'), value: 'caratula'},

        {text: this.$t('wolke.expediente.labels.montoDemanda'), value: 'montoDemanda'},
        {text: this.$t('wolke.expediente.labels.fechaDemanda'), value: 'fechaDemanda'},
        //{text: this.$t('wolke.expediente.labels.tribunal'), value: 'tribunal'},
       // {text: this.$t('wolke.expediente.labels.tipoJuicio'), value: 'tipoJuicio'},
        {text: this.$t('wolke.expediente.labels.provincia'), value: 'provincia'},
        {text: this.$t('wolke.expediente.labels.estado'), value: 'estado'},
        {text: this.$t('wolke.expediente.labels.etapa'), value: 'etapa'},
        //{text: this.$t('wolke.expediente.labels.patrocinante'), value: 'patrocinante'},
        //{text: this.$t('wolke.expediente.labels.cliente'), value: 'cliente'},
        //{text: this.$t('wolke.expediente.labels.beneficiario'), value: 'beneficiario'},
        //Actions
        {text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      ExpedienteProvider.paginateExpediente(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginateExpediente.items
        this.totalItems = r.data.paginateExpediente.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    }
  }

}
</script>


