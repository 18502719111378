<template>
    <crud-show :title="title" :open="open"  @close="$emit('close')">

        <v-card-text>
            <expediente-form :value="item" readonly></expediente-form>
        </v-card-text>

  </crud-show>
</template>

<script>
    import ExpedienteForm from "../ExpedienteForm";
    import {CrudShow} from '@dracul/common-frontend'

    export default {
        name: "ExpedienteShow",
        components: {CrudShow, ExpedienteForm},
        props: {
            open: {type: Boolean, default: true},
            item: {type: Object, required: true}
        },
        data() {
            return {
                title: 'wolke.expediente.showing',
            }
        },
    }
</script>

