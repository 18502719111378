import graphqlClient from "../../../apollo";

class PatrocinanteProvider {

    findPatrocinante(id) {
        return graphqlClient.query({
            query: require('./gql/Patrocinante/findPatrocinante.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchPatrocinante() {
        return graphqlClient.query({
        query: require('./gql/Patrocinante/fetchPatrocinante.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginatePatrocinante(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Patrocinante/paginatePatrocinante.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createPatrocinante(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Patrocinante/createPatrocinante.graphql'),
            variables: {input}
        })
    }
    
    updatePatrocinante(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Patrocinante/updatePatrocinante.graphql'),
            variables: {id, input}
        })
    }
    
     deletePatrocinante(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Patrocinante/deletePatrocinante.graphql'),
            variables: {id}
        })
    }

}

export default new PatrocinanteProvider()


