import merge from 'deepmerge'
import TribunalMessages from './messages/TribunalMessages'
import PatrocinanteMessages from './messages/PatrocinanteMessages'
import TipoJuicioMessages from './messages/TipoJuicioMessages'
import EstadoExpedienteMessages from './messages/EstadoExpedienteMessages'
import ProvinciaMessages from './messages/ProvinciaMessages'
import LocalidadMessages from './messages/LocalidadMessages'
import ClienteMessages from './messages/ClienteMessages'
import BeneficiarioMessages from './messages/BeneficiarioMessages'
import ExpedienteMessages from './messages/ExpedienteMessages'
import EtapaExpedienteMessages from './messages/EtapaExpedienteMessages'

import TribunalPermissionMessages from './permissions/TribunalPermissionMessages'
import PatrocinantePermissionMessages from './permissions/PatrocinantePermissionMessages'
import TipoJuicioPermissionMessages from './permissions/TipoJuicioPermissionMessages'
import EstadoExpedientePermissionMessages from './permissions/EstadoExpedientePermissionMessages'
import ProvinciaPermissionMessages from './permissions/ProvinciaPermissionMessages'
import LocalidadPermissionMessages from './permissions/LocalidadPermissionMessages'
import ClientePermissionMessages from './permissions/ClientePermissionMessages'
import BeneficiarioPermissionMessages from './permissions/BeneficiarioPermissionMessages'
import ExpedientePermissionMessages from './permissions/ExpedientePermissionMessages'
import EtapaExpedientePermissionMessages from './permissions/EtapaExpedientePermissionMessages'


const messages = merge.all([
    TribunalMessages,
    PatrocinanteMessages,
    TipoJuicioMessages,
    EstadoExpedienteMessages,
    ProvinciaMessages,
    LocalidadMessages,
    ClienteMessages,
    BeneficiarioMessages,
    ExpedienteMessages,
    EtapaExpedienteMessages,

    TribunalPermissionMessages,
    PatrocinantePermissionMessages,
    TipoJuicioPermissionMessages,
    EstadoExpedientePermissionMessages,
    ProvinciaPermissionMessages,
    LocalidadPermissionMessages,
    ClientePermissionMessages,
    BeneficiarioPermissionMessages,
    ExpedientePermissionMessages,
    EtapaExpedientePermissionMessages
])

export default messages;
