import graphqlClient from "../../../apollo";

class LocalidadProvider {

    findLocalidad(id) {
        return graphqlClient.query({
            query: require('./gql/Localidad/findLocalidad.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchLocalidad() {
        return graphqlClient.query({
        query: require('./gql/Localidad/fetchLocalidad.graphql'),
        fetchPolicy: "network-only"
        })
    }

    fetchLocalidadByProvincia(provinciaId) {
        return graphqlClient.query({
            query: require('./gql/Localidad/fetchLocalidadByProvincia.graphql'),
            fetchPolicy: "network-only",
            variables: {provinciaId:provinciaId}
        })
    }

    paginateLocalidad(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Localidad/paginateLocalidad.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createLocalidad(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Localidad/createLocalidad.graphql'),
            variables: {input}
        })
    }

    updateLocalidad(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Localidad/updateLocalidad.graphql'),
            variables: {id, input}
        })
    }

     deleteLocalidad(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Localidad/deleteLocalidad.graphql'),
            variables: {id}
        })
    }

}

export default new LocalidadProvider()


