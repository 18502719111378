
const messages = {
    en: {
       role: {
            permissions: {
                LOCALIDAD_MENU: "Access to Localidad",
                LOCALIDAD_SHOW: "View Localidad",
                LOCALIDAD_CREATE: "Create Localidad",
                LOCALIDAD_UPDATE: "Modify Localidad",
                LOCALIDAD_DELETE: "Delete Localidad",
            }
       }
    },
    es: {
        role: {
            permissions: {
                LOCALIDAD_MENU: "Acceder Localidad",
                LOCALIDAD_SHOW: "Ver Localidad",
                LOCALIDAD_CREATE: "Crear Localidad",
                LOCALIDAD_UPDATE: "Modificar Localidad",
                LOCALIDAD_DELETE: "Eliminar Localidad",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                LOCALIDAD_MENU: "Acessar Localidad",
                LOCALIDAD_SHOW: "Ver Localidad",
                LOCALIDAD_CREATE: "Criar Localidad",
                LOCALIDAD_UPDATE: "Modificar Localidad",
                LOCALIDAD_DELETE: "Eliminar Localidad",
            }
       }
    }
}

export default messages
    