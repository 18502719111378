<template>
  <v-row>

    <v-col cols="12" sm="2">
      <v-text-field
          name="codigo"
          v-model="form.codigo"
          :label="$t('wolke.etapaExpediente.labels.codigo')"
          :placeholder="$t('wolke.etapaExpediente.labels.codigo')"
          :error="hasInputErrors('codigo')"
          :error-messages="getInputErrors('codigo')"
          color="secondary"
          :rules="required"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3">
      <v-text-field
          name="nombre"
          v-model="form.nombre"
          :label="$t('wolke.etapaExpediente.labels.nombre')"
          :placeholder="$t('wolke.etapaExpediente.labels.nombre')"
          :error="hasInputErrors('nombre')"
          :error-messages="getInputErrors('nombre')"
          color="secondary"
          :rules="required"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3">
      <etapa-expediente-combobox
          v-model="form.siguienteEtapa"
          clearable
          :provincia="provincia"
          label="wolke.etapaExpediente.labels.siguienteEtapa"
      ></etapa-expediente-combobox>
    </v-col>


    <v-col cols="12" sm="2">
      <v-checkbox
          name="requiereFecha"
          v-model="form.requiereFecha"
          :label="$t('wolke.etapaExpediente.labels.requiereFecha')"
          :error="hasInputErrors('requiereFecha')"
          :error-messages="getInputErrors('requiereFecha')"
          color="secondary"
      ></v-checkbox>
    </v-col>

    <v-col cols="12" sm="2">
      <movimiento-requiere-adjunto-combobox
          v-model="form.requiereAdjunto"
      ></movimiento-requiere-adjunto-combobox>
    </v-col>

  </v-row>
</template>

<script>
import {RequiredRule, InputErrorsByProps} from "@dracul/common-frontend";
import EtapaExpedienteCombobox from "@/modules/wolke/components/EtapaExpedienteCombobox/EtapaExpedienteCombobox.vue";
import MovimientoRequiereAdjuntoCombobox
  from "@/modules/wolke/components/MovimientoRequiereAdjuntoCombobox/MovimientoRequiereAdjuntoCombobox.vue";

export default {
  name: "MovimientoEtapaExpedienteForm",
  components: {MovimientoRequiereAdjuntoCombobox, EtapaExpedienteCombobox},
  mixins: [RequiredRule, InputErrorsByProps],
  props: {
    value: {type: Object, required: true},
    provincia: {type: String}
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
