<template>
  <v-autocomplete
      prepend-icon="sensor_occupied"
      :items="items"
      :item-text="'nombre'"
      :item-value="itemValue"
      v-model="item"
      :label="$t('wolke.expediente.labels.movimiento')"
      :loading="loading"
      :error="hasInputErrors('movimiento')"
      :error-messages="getInputErrors('movimiento')"
      color="secondary"
      item-color="secondary"
      :rules="isRequired ? required : []"
      :multiple="multiple"
      :chips="chips"
      :solo="solo"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :hide-details="hideDetails"
      :style="{width: width, maxWidth: width}"
      :return-object="true"
  ></v-autocomplete>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import EtapaExpedienteProvider from "@/modules/wolke/providers/EtapaExpedienteProvider";

export default {
  name: "MovimientoExpedienteCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    etapaId: {type: String, required: true},
    multiple: {type: Boolean, default: false},
    solo: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    isRequired: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    itemValue: {type: String, default: 'nombre'},

    width: {type: String, default: null},
  },
  data() {
    return {
      etapa: null,
      loading: false
    }
  },
  watch: {
    etapaId(){
      this.fetch()
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        if(this.returnObject){
          this.$emit('input', val)
        }else{
          this.$emit('input', val[this.itemValue])
        }

        this.$emit('inputObject', val)
      }
    },
    items(){
      return (this?.etapa?.movimientos && this?.etapa?.movimientos.length > 0) ? this.etapa.movimientos : []
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetch() {
      this.loading = true
      EtapaExpedienteProvider.findEtapaExpediente(this.etapaId)
          .then(r => {this.etapa = r.data.findEtapaExpediente})
          .catch(err => console.error(err))
          .finally(() => this.loading = false)
    }

  }
}
</script>

<style scoped>

</style>
