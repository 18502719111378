<template>
  <v-autocomplete
      prepend-icon="pin_drop"
      :items="items"
      :item-text="'nombre'"
      :item-value="itemValue"
      v-model="item"
      :label="$t('wolke.beneficiario.labels.localidad')"
      :loading="loading"
      :error="hasInputErrors('localidad')"
      :error-messages="getInputErrors('localidad')"
      color="secondary"
      item-color="secondary"
      :rules="isRequired ? required : []"
      :multiple="multiple"
      :chips="chips"
      :solo="solo"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :hide-details="hideDetails"
      :style="{width: width, maxWidth: width}"
      :return-object="returnObject"
  ></v-autocomplete>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import LocalidadProvider from "../../providers/LocalidadProvider"

export default {
  name: "LocalidadCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    provincia: {type: String},
    multiple: {type: Boolean, default: false},
    solo: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    isRequired: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    itemValue: {type: String, default: 'id'},
    width: {type: String, default: null},
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.fetch()
  },
  watch: {
    provincia: {
      handler() {
        this.$emit('input', null)
        this.fetch()
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetch() {
      this.loading = true

      if (this.provincia) {
        LocalidadProvider.fetchLocalidadByProvincia(this.provincia)
            .then(r => {this.items = r.data.fetchLocalidadByProvincia})
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
      } else {
        LocalidadProvider.fetchLocalidad()
            .then(r => {this.items = r.data.fetchLocalidad})
            .catch(err => console.error(err))
            .finally(() => this.loading = false)
      }


    }

  }
}
</script>

<style scoped>

</style>
