
const messages = {
    en: {
       wolke: {
          provincia: { 
            name: 'Provincia',
            title: 'Provincia management',
            subtitle: 'View, search, create, edit and delete Provincia',
            creating: 'Creating Provincia',
            editing: 'Editing Provincia',
            deleting: 'Deleting Provincia',
            showing: 'Showing Provincia',
            menu: 'Provincia',
            labels: {
                nombre: 'Province'
            },
          
          }
       }
    },
    es: {
       wolke: {
          provincia: { 
            name: 'Provincia',
            title: 'Administración de Provincia',
            subtitle: 'Ver, buscar, crear, editar, y borrar Provincia',
            creating: 'Creando Provincia',
            editing: 'Modificando Provincia',
            deleting: 'Eliminando Provincia',
            showing: 'Detalles de Provincia',
            menu: 'Provincia',
            labels: {
                nombre: 'Provincia'
            },
          
          }
       }
    },
    pt: {
       wolke: {
          provincia: { 
            name: 'Provincia',
            title: 'Administração de Provincia',
            subtitle: 'Ver, buscar, criar, editar e usar Provincia',
            creating: 'Criando Provincia',
            editing: 'Edição Provincia',
            deleting: 'Apagando Provincia',
            showing: 'Detalhes do Provincia',
            menu: 'Provincia',
            labels: {
                nombre: 'Provincia'
            },
          
          }
       }
    }
}

export default messages
    