<template>
  <v-data-table
  :headers="headers"
  :items="movimientos"
  disable-pagination
  hide-default-footer
  dense
  >

    <template v-slot:item.fechaCreacion="{ item }">
      {{ getDateFormat(item.fechaCreacion) }}
    </template>

    <template v-slot:item.fecha="{ item }">
      {{ getDateFormat(item.fecha) }}
    </template>

    <template v-slot:item.adjunto="{ item }">
      <v-btn v-if="item.adjunto" icon :href="item.adjunto" target="_blank"><v-icon>link</v-icon></v-btn>
    </template>


    <template v-slot:item.etapaOrigen="{ item }">
      <etapa-expediente-view :etapa-id="item.etapaOrigen" raw></etapa-expediente-view>
    </template>

    <template v-slot:item.etapaDestino="{ item }">
      <etapa-expediente-view :etapa-id="item.etapaDestino" raw></etapa-expediente-view>
    </template>

    <template slot="no-data">
      <div class="text-xs-center" v-t="'common.noData'"></div>
    </template>

    <template slot="loading">
      <div class="text-xs-center" v-t="'common.loading'"></div>
    </template>

  </v-data-table>
</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";
import EtapaExpedienteView from "@/modules/wolke/components/EtapaExpedienteView/EtapaExpedienteView.vue";

export default {
  name: "ListaMovimientosExpediente",
  components: {EtapaExpedienteView},
  mixins: [DayjsMixin],
  props: {
    movimientos: {type: Array, required: true},
  },
  computed: {
    headers(){
      return [
        {text: 'Fecha creación', value: 'fechaCreacion'},
        {text: 'Nombre Movimiento', value: 'nombreMovimiento'},
        {text: 'Fecha', value: 'fecha'},
        {text: 'Adjunto', value: 'adjunto'},
        {text: 'Etapa origen', value: 'etapaOrigen'},
        {text: 'Etapa destino', value: 'etapaDestino'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
