
const messages = {
    en: {
       wolke: {
          tipoJuicio: { 
            name: 'TipoJuicio',
            title: 'TipoJuicio management',
            subtitle: 'View, search, create, edit and delete TipoJuicio',
            creating: 'Creating TipoJuicio',
            editing: 'Editing TipoJuicio',
            deleting: 'Deleting TipoJuicio',
            showing: 'Showing TipoJuicio',
            menu: 'TipoJuicio',
            labels: {
                codigo: 'Código',
                nombre: 'nombre'
            },
          
          }
       }
    },
    es: {
       wolke: {
          tipoJuicio: { 
            name: 'TipoJuicio',
            title: 'Administración de TipoJuicio',
            subtitle: 'Ver, buscar, crear, editar, y borrar TipoJuicio',
            creating: 'Creando TipoJuicio',
            editing: 'Modificando TipoJuicio',
            deleting: 'Eliminando TipoJuicio',
            showing: 'Detalles de TipoJuicio',
            menu: 'TipoJuicio',
            labels: {
                codigo: 'Código',
                nombre: 'nombre'
            },
          
          }
       }
    },
    pt: {
       wolke: {
          tipoJuicio: { 
            name: 'TipoJuicio',
            title: 'Administração de TipoJuicio',
            subtitle: 'Ver, buscar, criar, editar e usar TipoJuicio',
            creating: 'Criando TipoJuicio',
            editing: 'Edição TipoJuicio',
            deleting: 'Apagando TipoJuicio',
            showing: 'Detalhes do TipoJuicio',
            menu: 'TipoJuicio',
            labels: {
                codigo: 'Código',
                nombre: 'nombre'
            },
          
          }
       }
    }
}

export default messages
    