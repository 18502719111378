<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field
            prepend-icon="short_text"
            name="nombre"
            v-model="form.nombre"
            :label="$t('wolke.tribunal.labels.nombre')"
            :placeholder="$t('wolke.tribunal.labels.nombre')"
            :error="hasInputErrors('nombre')"
            :error-messages="getInputErrors('nombre')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            prepend-icon="article"
            name="secretaria"
            v-model="form.secretaria"
            :label="$t('wolke.tribunal.labels.secretaria')"
            :placeholder="$t('wolke.tribunal.labels.secretaria')"
            :error="hasInputErrors('secretaria')"
            :error-messages="getInputErrors('secretaria')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <provincia-combobox v-model="form.provincia" :input-errors="inputErrors"/>
      </v-col>


      <v-col cols="12" sm="6">
        <localidad-combobox v-model="form.localidad" :provincia="form.provincia" :input-errors="inputErrors"/>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            prepend-icon="location_on"
            name="domicilio"
            v-model="form.domicilio"
            :label="$t('wolke.tribunal.labels.domicilio')"
            :placeholder="$t('wolke.tribunal.labels.domicilio')"
            :error="hasInputErrors('domicilio')"
            :error-messages="getInputErrors('domicilio')"
            color="secondary"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="email"
            name="email"
            v-model="form.email"
            :label="$t('wolke.tribunal.labels.email')"
            :placeholder="$t('wolke.tribunal.labels.email')"
            :error="hasInputErrors('email')"
            :error-messages="getInputErrors('email')"
            color="secondary"

        ></v-text-field>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
import ProvinciaCombobox from "@/modules/wolke/components/ProvinciaCombobox";
import LocalidadCombobox from "@/modules/wolke/components/LocalidadCombobox/LocalidadCombobox.vue";


export default {
  name: "TribunalForm",
  components: {LocalidadCombobox, ProvinciaCombobox},
  mixins: [InputErrorsByProps, RequiredRule],

  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

