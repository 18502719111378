import graphqlClient from "../../../apollo";

class BeneficiarioProvider {

    findBeneficiario(id) {
        return graphqlClient.query({
            query: require('./gql/Beneficiario/findBeneficiario.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchBeneficiario() {
        return graphqlClient.query({
        query: require('./gql/Beneficiario/fetchBeneficiario.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginateBeneficiario(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Beneficiario/paginateBeneficiario.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createBeneficiario(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Beneficiario/createBeneficiario.graphql'),
            variables: {input}
        })
    }
    
    updateBeneficiario(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Beneficiario/updateBeneficiario.graphql'),
            variables: {id, input}
        })
    }
    
     deleteBeneficiario(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Beneficiario/deleteBeneficiario.graphql'),
            variables: {id}
        })
    }

}

export default new BeneficiarioProvider()


