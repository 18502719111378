
const messages = {
    en: {
       role: {
            permissions: {
                TIPOJUICIO_MENU: "Access to TipoJuicio",
                TIPOJUICIO_SHOW: "View TipoJuicio",
                TIPOJUICIO_CREATE: "Create TipoJuicio",
                TIPOJUICIO_UPDATE: "Modify TipoJuicio",
                TIPOJUICIO_DELETE: "Delete TipoJuicio",
            }
       }
    },
    es: {
        role: {
            permissions: {
                TIPOJUICIO_MENU: "Acceder TipoJuicio",
                TIPOJUICIO_SHOW: "Ver TipoJuicio",
                TIPOJUICIO_CREATE: "Crear TipoJuicio",
                TIPOJUICIO_UPDATE: "Modificar TipoJuicio",
                TIPOJUICIO_DELETE: "Eliminar TipoJuicio",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                TIPOJUICIO_MENU: "Acessar TipoJuicio",
                TIPOJUICIO_SHOW: "Ver TipoJuicio",
                TIPOJUICIO_CREATE: "Criar TipoJuicio",
                TIPOJUICIO_UPDATE: "Modificar TipoJuicio",
                TIPOJUICIO_DELETE: "Eliminar TipoJuicio",
            }
       }
    }
}

export default messages
    