
const messages = {
    en: {
       wolke: {
          estadoExpediente: { 
            name: 'EstadoExpediente',
            title: 'EstadoExpediente management',
            subtitle: 'View, search, create, edit and delete EstadoExpediente',
            creating: 'Creating EstadoExpediente',
            editing: 'Editing EstadoExpediente',
            deleting: 'Deleting EstadoExpediente',
            showing: 'Showing EstadoExpediente',
            menu: 'EstadoExpediente',
            labels: {
                codigo: 'Code',
                nombre: 'Name',
                tituloInforme: 'Título Informe'
            },
          
          }
       }
    },
    es: {
       wolke: {
          estadoExpediente: { 
            name: 'EstadoExpediente',
            title: 'Administración de EstadoExpediente',
            subtitle: 'Ver, buscar, crear, editar, y borrar EstadoExpediente',
            creating: 'Creando EstadoExpediente',
            editing: 'Modificando EstadoExpediente',
            deleting: 'Eliminando EstadoExpediente',
            showing: 'Detalles de EstadoExpediente',
            menu: 'EstadoExpediente',
            labels: {
                codigo: 'Código',
                nombre: 'Nombre',
                tituloInforme: 'Título Informe'
            },
          
          }
       }
    },
    pt: {
       wolke: {
          estadoExpediente: { 
            name: 'EstadoExpediente',
            title: 'Administração de EstadoExpediente',
            subtitle: 'Ver, buscar, criar, editar e usar EstadoExpediente',
            creating: 'Criando EstadoExpediente',
            editing: 'Edição EstadoExpediente',
            deleting: 'Apagando EstadoExpediente',
            showing: 'Detalhes do EstadoExpediente',
            menu: 'EstadoExpediente',
            labels: {
                codigo: 'Código',
                nombre: 'Nome',
                tituloInforme: 'Título Informe'
            },
          
          }
       }
    }
}

export default messages
    