<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.nombre" :label="$t('wolke.beneficiario.labels.nombre')" icon="short_text"/>
                 <show-field :value="item.provincia.nombre" :label="$t('wolke.beneficiario.labels.provincia')" icon="add_location_alt"/>
                 <show-field :value="item.cbu" :label="$t('wolke.beneficiario.labels.cbu')" icon="account_balance"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.cuit" :label="$t('wolke.beneficiario.labels.cuit')" icon="badge"/>
                 <show-field :value="item.localidad.nombre" :label="$t('wolke.beneficiario.labels.localidad')" icon="pin_drop"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.domicilioLegal" :label="$t('wolke.beneficiario.labels.domicilioLegal')" icon="location_on"/>
                 <show-field :value="item.situacionImpositiva" :label="$t('wolke.beneficiario.labels.situacionImpositiva')" icon="workspaces"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
     
    
    export default {
        name: 'BeneficiarioShowData',
        components: {ShowField},
        
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

