
const messages = {
    en: {
       role: {
            permissions: {
                ESTADOEXPEDIENTE_MENU: "Access to EstadoExpediente",
                ESTADOEXPEDIENTE_SHOW: "View EstadoExpediente",
                ESTADOEXPEDIENTE_CREATE: "Create EstadoExpediente",
                ESTADOEXPEDIENTE_UPDATE: "Modify EstadoExpediente",
                ESTADOEXPEDIENTE_DELETE: "Delete EstadoExpediente",
            }
       }
    },
    es: {
        role: {
            permissions: {
                ESTADOEXPEDIENTE_MENU: "Acceder EstadoExpediente",
                ESTADOEXPEDIENTE_SHOW: "Ver EstadoExpediente",
                ESTADOEXPEDIENTE_CREATE: "Crear EstadoExpediente",
                ESTADOEXPEDIENTE_UPDATE: "Modificar EstadoExpediente",
                ESTADOEXPEDIENTE_DELETE: "Eliminar EstadoExpediente",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                ESTADOEXPEDIENTE_MENU: "Acessar EstadoExpediente",
                ESTADOEXPEDIENTE_SHOW: "Ver EstadoExpediente",
                ESTADOEXPEDIENTE_CREATE: "Criar EstadoExpediente",
                ESTADOEXPEDIENTE_UPDATE: "Modificar EstadoExpediente",
                ESTADOEXPEDIENTE_DELETE: "Eliminar EstadoExpediente",
            }
       }
    }
}

export default messages
    