<template>
  <div>
    <v-simple-table
    dense
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">

          </th>

          <th class="text-left">
            N° Prod
          </th>
          <th class="text-left">
            Producto
          </th>
          <th class="text-left">
           Capital
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in deudas"
            :key="item.name"
        >
          <td>
            <v-checkbox
                dense hide-details
                :key="item.id"
                v-model="form"
                :value="item.id"
                :readonly="readonly"
            ></v-checkbox>
          </td>

          <td>{{ item.nroProducto }}</td>
          <td>{{ item.producto }}</td>
          <td>{{currency}}{{ item.capitalTotal.toLocaleString('es-AR') }}</td>
        </tr>
        </tbody>
      </template>

    </v-simple-table>

  </div>

</template>

<script>
import DeudaProvider from "@/modules/cobrox/providers/DeudaProvider";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "ExpedienteDeudaForm",
  mixins: [CurrencyMixin],
  props: {
    cuils: {type:Array},
    value: {type: Array, required: true},
    readonly: {type: Boolean, default:false}
  },
  data(){
    return {
      loading: false,
      deudas: []
    }
  },
  methods: {
    fetchDeudaByCuils(){

      if(!this.getCuils || this.getCuils.length == 0){
        return
      }

      this.loading = true
      DeudaProvider.fetchDeudaByCuils(this.getCuils)
          .then(response => {
            this.deudas = response.data.fetchDeudaByCuils
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {this.loading = false})
    }
  },
  computed: {
    headers(){
      return [
        { text: 'ID', value: 'idDeuda' },
        { text: 'N° PROD', value: 'nroProducto' },
        { text: 'PRODUCTO', value: 'producto' },
        { text: 'CAPITAL', value: 'capitalTotal' },
      ]
    },
    form: {
      get() {
        return this.value
      },
      set(val) {
        console.log("val", val)
          this.$emit('input', val)

      }
    },
    getCuils() {
      return this.cuils.filter(cuil => { return cuil != null && cuil != '' })
    }
  },
  created() {
    this.fetchDeudaByCuils()
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    cuils: {
      handler() {
        this.fetchDeudaByCuils()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
