<template>
  <v-card v-if="cardView">
    <v-card-text>
      <expediente-form ref="form"
                       v-model="form"
                       :input-errors="inputErrors"
                       :expediente-id="id"
                       :movimientos="movimientos"
                       updating
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <submit-button :loading="loading" @click="update" text="common.update"></submit-button>
    </v-card-actions>

    <snackbar v-model="flash"/>
  </v-card>

  <crud-update v-else :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update" fullscreen
               @close="$emit('close')"
  >
    <expediente-form ref="form"
                     v-model="form"
                     :input-errors="inputErrors"
                     :expediente-id="id"
                     :movimientos="movimientos"
                     updating
    />
  </crud-update>


</template>

<script>

import ExpedienteProvider from "../../../../providers/ExpedienteProvider";

import {CrudUpdate, ClientError, SubmitButton, Snackbar} from '@dracul/common-frontend'

import ExpedienteForm from "../ExpedienteForm";

import {Dayjs} from "@dracul/dayjs-frontend";

export default {
  name: "ExpedienteUpdate",

  components: {ExpedienteForm, CrudUpdate, SubmitButton, Snackbar},

  props: {
    cardView: {type: Boolean, default: false},
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'wolke.expediente.editing',
      flash: null,
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      movimientos: this.item?.movimientos?.length > 0 ? this.item.movimientos : [],
      form: {
        numeroExpediente: this.item.numeroExpediente,
        caratula: this.item.caratula,
        montoDemanda: this.item.montoDemanda,
        fechaDemanda: this.item.fechaDemanda ? Dayjs(parseInt(this.item.fechaDemanda)) : null,
        tribunal: this.item.tribunal ? this.item.tribunal.id : null,
        tipoJuicio: this.item.tipoJuicio ? this.item.tipoJuicio.id : null,
        estado: this.item.estado ? this.item.estado.id : null,
        provincia: this.item.provincia ? this.item.provincia.id : null,
        patrocinante: this.item.patrocinante ? this.item.patrocinante.id : null,
        cliente: this.item.cliente ? this.item.cliente.id : null,
        beneficiario: this.item.beneficiario ? this.item.beneficiario.id : null,
        personas: this.item?.personas?.length > 0 ? this.item.personas : [],
        deudas: this.item?.deudas?.length > 0 ? this.item.deudas : [],
        etapa: this.item.etapa ? this.item.etapa.id : null,

      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true
        ExpedienteProvider.updateExpediente(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updateExpediente)
                this.$emit('close')
                this.flash= this.$t("common.updated")
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

