<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">

      <v-card outlined width="100%">
        <v-card-text>
          <v-row>

            <v-col cols="12" sm="4">
              <v-text-field

                  prepend-icon="tag"
                  name="numeroExpediente"
                  v-model="form.numeroExpediente"
                  :label="$t('wolke.expediente.labels.numeroExpediente')"
                  :placeholder="$t('wolke.expediente.labels.numeroExpediente')"
                  :error="hasInputErrors('numeroExpediente')"
                  :error-messages="getInputErrors('numeroExpediente')"
                  color="secondary"
                  :rules="required"
                  :readonly="readonly"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="8">
              <v-text-field

                  prepend-icon="insert_drive_file"
                  name="caratula"
                  v-model="form.caratula"
                  :label="$t('wolke.expediente.labels.caratula')"
                  :placeholder="$t('wolke.expediente.labels.caratula')"
                  :error="hasInputErrors('caratula')"
                  :error-messages="getInputErrors('caratula')"
                  color="secondary"
                  :readonly="readonly"
              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="4">
              <v-text-field

                  prepend-icon="paid"
                  name="montoDemanda"
                  v-model.number="form.montoDemanda"
                  type="number"
                  :label="$t('wolke.expediente.labels.montoDemanda')"
                  :placeholder="$t('wolke.expediente.labels.montoDemanda')"
                  :error="hasInputErrors('montoDemanda')"
                  :error-messages="getInputErrors('montoDemanda')"
                  color="secondary"
                  :readonly="readonly"
              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="4">
              <date-input
                  v-model="form.fechaDemanda"
                  :label="$t('wolke.expediente.labels.fechaDemanda')"
                  :error="hasInputErrors('fechaDemanda')"
                  :error-messages="getInputErrors('fechaDemanda')"
                  :readonly="readonly"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <estado-expediente-combobox v-model="form.estado" :input-errors="inputErrors"  :readonly="readonly"/>
            </v-col>

            <v-col cols="12" sm="4">
              <tribunal-combobox v-model="form.tribunal" :input-errors="inputErrors"  :readonly="readonly"/>
            </v-col>


            <v-col cols="12" sm="4">
              <tipo-juicio-combobox v-model="form.tipoJuicio" :input-errors="inputErrors"  :readonly="readonly"/>
            </v-col>


            <v-col cols="12" sm="4">
              <provincia-combobox v-model="form.provincia" :input-errors="inputErrors"  :readonly="readonly"/>
            </v-col>

          </v-row>

        </v-card-text>
      </v-card>


      <v-card outlined width="100%" class="mt-2">
        <v-card-text>
          <v-row>

            <v-col cols="12" sm="4">
              <patrocinante-combobox v-model="form.patrocinante" :input-errors="inputErrors"  :readonly="readonly" />
            </v-col>


            <v-col cols="12" sm="4">
              <cliente-combobox v-model="form.cliente" :input-errors="inputErrors"  :readonly="readonly" />
            </v-col>


            <v-col cols="12" sm="4">
              <beneficiario-combobox v-model="form.beneficiario" :input-errors="inputErrors"  :readonly="readonly" />
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

    <v-row>
      <v-col cols="12" sm="6">
        <v-card outlined width="100%" class="mt-2">
          <v-card-title>Personas</v-card-title>
          <v-card-text>

            <form-list :new-item="{caracter: null, cuil: null}" v-model="form.personas" :readonly="readonly">
              <template v-slot:default="{item,index}">
                <expediente-persona-form
                    v-model="form.personas[index]"
                    :key="index"
                    :readonly="readonly"
                ></expediente-persona-form>
              </template>
            </form-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card outlined width="100%" class="mt-2">
          <v-card-title>Deudas</v-card-title>
          <v-card-text>
            <expediente-deudas-form v-model="form.deudas" :cuils="getCuils" :readonly="readonly"></expediente-deudas-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card outlined width="100%" class="mt-2">

      <v-card-title><span class="mr-2 grey--text text--darken-2">ETAPA: </span> <etapa-expediente-view :etapa-id="form.etapa" raw></etapa-expediente-view> </v-card-title>
      <v-card-text v-if="!form.etapa || movimientosLocal.length == 0">
        <etapa-expediente-combobox
            v-model="form.etapa"
            :input-errors="inputErrors"
        ></etapa-expediente-combobox>
      </v-card-text>

      <v-card-text v-if="!!form.etapa">
        <expediente-movimientos-form
            :etapa-id="form.etapa"
            :expediente-id="expedienteId"
            :movimientos="movimientosLocal"
            :input-errors="inputErrors"
            :updating="updating"
            @movimientoAgregado="onMovimientoAgregado"
            @movimientoPreparado="onMovimientoPreparado"
            :readonly="readonly"
        ></expediente-movimientos-form>
      </v-card-text>
    </v-card>


  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule, FormList} from '@dracul/common-frontend'

import TribunalCombobox from "../../../../components/TribunalCombobox";
import TipoJuicioCombobox from "../../../../components/TipoJuicioCombobox";
import EstadoExpedienteCombobox from "../../../../components/EstadoExpedienteCombobox";
import ProvinciaCombobox from "../../../../components/ProvinciaCombobox";
import PatrocinanteCombobox from "../../../../components/PatrocinanteCombobox";
import ClienteCombobox from "../../../../components/ClienteCombobox";
import BeneficiarioCombobox from "../../../../components/BeneficiarioCombobox";

import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";
import ExpedientePersonaForm
  from "@/modules/wolke/pages/crud/ExpedienteCrudPage/ExpedienteForm/ExpedientePersonaForm";
import ExpedienteDeudasForm from "@/modules/wolke/pages/crud/ExpedienteCrudPage/ExpedienteForm/ExpedienteDeudaForm"
import EtapaExpedienteCombobox from "@/modules/wolke/components/EtapaExpedienteCombobox";
import ExpedienteMovimientosForm
  from "@/modules/wolke/pages/crud/ExpedienteCrudPage/ExpedienteForm/ExpedienteMovimientosForm/ExpedienteMovimientosForm.vue";
import EtapaExpedienteView from "@/modules/wolke/components/EtapaExpedienteView/EtapaExpedienteView.vue";


export default {
  name: "ExpedienteForm",
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  components: {
    EtapaExpedienteView,
    ExpedienteMovimientosForm,
    EtapaExpedienteCombobox,
    ExpedientePersonaForm,
    ExpedienteDeudasForm,
    TribunalCombobox,
    TipoJuicioCombobox,
    EstadoExpedienteCombobox,
    ProvinciaCombobox,
    PatrocinanteCombobox,
    ClienteCombobox,
    BeneficiarioCombobox,
    DateInput,
    FormList
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    expedienteId: {
      type: String,
      required: false
    },
    movimientos: {
      type: Array,
      required: false
    },
    updating: {
      type: Boolean,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    getCuils(){
      return this.form.personas.map(persona => persona.cuil)
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    onMovimientoAgregado(expediente){
      this.form.etapa = expediente.etapa.id
      this.movimientosLocal = expediente.movimientos
    },
    onMovimientoPreparado(movimiento){
      this.movimientosLocal.push(movimiento)
    }
  },
  data() {
    return {
      fechaDemandaDateMenu: false,
      movimientosLocal: this.movimientos || [],
    }
  }
}
</script>

<style scoped>

</style>

