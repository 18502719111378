
const messages = {
    en: {
       role: {
            permissions: {
                CLIENTE_MENU: "Access to Cliente",
                CLIENTE_SHOW: "View Cliente",
                CLIENTE_CREATE: "Create Cliente",
                CLIENTE_UPDATE: "Modify Cliente",
                CLIENTE_DELETE: "Delete Cliente",
            }
       }
    },
    es: {
        role: {
            permissions: {
                CLIENTE_MENU: "Acceder Cliente",
                CLIENTE_SHOW: "Ver Cliente",
                CLIENTE_CREATE: "Crear Cliente",
                CLIENTE_UPDATE: "Modificar Cliente",
                CLIENTE_DELETE: "Eliminar Cliente",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                CLIENTE_MENU: "Acessar Cliente",
                CLIENTE_SHOW: "Ver Cliente",
                CLIENTE_CREATE: "Criar Cliente",
                CLIENTE_UPDATE: "Modificar Cliente",
                CLIENTE_DELETE: "Eliminar Cliente",
            }
       }
    }
}

export default messages
    