
const messages = {
    en: {
       role: {
            permissions: {
                TRIBUNAL_MENU: "Access to Tribunal",
                TRIBUNAL_SHOW: "View Tribunal",
                TRIBUNAL_CREATE: "Create Tribunal",
                TRIBUNAL_UPDATE: "Modify Tribunal",
                TRIBUNAL_DELETE: "Delete Tribunal",
            }
       }
    },
    es: {
        role: {
            permissions: {
                TRIBUNAL_MENU: "Acceder Tribunal",
                TRIBUNAL_SHOW: "Ver Tribunal",
                TRIBUNAL_CREATE: "Crear Tribunal",
                TRIBUNAL_UPDATE: "Modificar Tribunal",
                TRIBUNAL_DELETE: "Eliminar Tribunal",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                TRIBUNAL_MENU: "Acessar Tribunal",
                TRIBUNAL_SHOW: "Ver Tribunal",
                TRIBUNAL_CREATE: "Criar Tribunal",
                TRIBUNAL_UPDATE: "Modificar Tribunal",
                TRIBUNAL_DELETE: "Eliminar Tribunal",
            }
       }
    }
}

export default messages
    