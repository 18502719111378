import graphqlClient from "../../../apollo";

class EtapaExpedienteProvider {

    findEtapaExpediente(id) {
        return graphqlClient.query({
            query: require('./gql/EtapaExpediente/findEtapaExpediente.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchEtapaExpediente() {
        return graphqlClient.query({
        query: require('./gql/EtapaExpediente/fetchEtapaExpediente.graphql'),
        fetchPolicy: "network-only"
        })
    }

    fetchEtapaExpedienteByProvincia(provinciaId) {
        return graphqlClient.query({
            query: require('./gql/EtapaExpediente/fetchEtapaExpedienteByProvincia.graphql'),
            fetchPolicy: "network-only",
            variables: {provinciaId:provinciaId}
        })
    }

    paginateEtapaExpediente(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/EtapaExpediente/paginateEtapaExpediente.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createEtapaExpediente(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/EtapaExpediente/createEtapaExpediente.graphql'),
            variables: {input}
        })
    }

    updateEtapaExpediente(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/EtapaExpediente/updateEtapaExpediente.graphql'),
            variables: {id, input}
        })
    }

     deleteEtapaExpediente(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/EtapaExpediente/deleteEtapaExpediente.graphql'),
            variables: {id}
        })
    }

}

export default new EtapaExpedienteProvider()


