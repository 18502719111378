import graphqlClient from "../../../apollo";

class TipoJuicioProvider {

    findTipoJuicio(id) {
        return graphqlClient.query({
            query: require('./gql/TipoJuicio/findTipoJuicio.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchTipoJuicio() {
        return graphqlClient.query({
        query: require('./gql/TipoJuicio/fetchTipoJuicio.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginateTipoJuicio(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/TipoJuicio/paginateTipoJuicio.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createTipoJuicio(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/TipoJuicio/createTipoJuicio.graphql'),
            variables: {input}
        })
    }
    
    updateTipoJuicio(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/TipoJuicio/updateTipoJuicio.graphql'),
            variables: {id, input}
        })
    }
    
     deleteTipoJuicio(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/TipoJuicio/deleteTipoJuicio.graphql'),
            variables: {id}
        })
    }

}

export default new TipoJuicioProvider()


