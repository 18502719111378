import graphqlClient from "../../../apollo";

class EstadoExpedienteProvider {

    findEstadoExpediente(id) {
        return graphqlClient.query({
            query: require('./gql/EstadoExpediente/findEstadoExpediente.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchEstadoExpediente() {
        return graphqlClient.query({
        query: require('./gql/EstadoExpediente/fetchEstadoExpediente.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginateEstadoExpediente(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/EstadoExpediente/paginateEstadoExpediente.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createEstadoExpediente(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/EstadoExpediente/createEstadoExpediente.graphql'),
            variables: {input}
        })
    }
    
    updateEstadoExpediente(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/EstadoExpediente/updateEstadoExpediente.graphql'),
            variables: {id, input}
        })
    }
    
     deleteEstadoExpediente(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/EstadoExpediente/deleteEstadoExpediente.graphql'),
            variables: {id}
        })
    }

}

export default new EstadoExpedienteProvider()


