import graphqlClient from "../../../apollo";

class TribunalProvider {

    findTribunal(id) {
        return graphqlClient.query({
            query: require('./gql/Tribunal/findTribunal.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchTribunal() {
        return graphqlClient.query({
        query: require('./gql/Tribunal/fetchTribunal.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginateTribunal(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Tribunal/paginateTribunal.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createTribunal(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Tribunal/createTribunal.graphql'),
            variables: {input}
        })
    }
    
    updateTribunal(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Tribunal/updateTribunal.graphql'),
            variables: {id, input}
        })
    }
    
     deleteTribunal(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Tribunal/deleteTribunal.graphql'),
            variables: {id}
        })
    }

}

export default new TribunalProvider()


