<template>
  <v-dialog
      v-model="dialog"
      max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        Agregar Movimiento
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Agregando Movimiento</span>
      </v-card-title>

      <v-card-text>
        <expediente-movimiento-form
            :etapa-id="etapaId"
            v-model="form"
            ref="form"
        >
        </expediente-movimiento-form>
      </v-card-text>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ExpedienteMovimientoForm from "@/modules/wolke/forms/ExpedienteMovimientoForm/ExpedienteMovimientoForm.vue";
import ExpedienteProvider from "@/modules/wolke/providers/ExpedienteProvider";

export default {
  name: "AgregarMovimientoExpediente",
  components: {ExpedienteMovimientoForm},
  props: {
    etapaId: {
      type: String,
      required: true
    },
    expedienteId: {
      type: String
    },
    updating: {
      type: Boolean,
      required: false
    }

  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        nombreMovimiento: "",
        fecha: null,
        adjunto: "",
        etapaOrigen: this.etapaId,
        etapaDestino: null,
      }
    }
  },
  watch: {
    etapaId(newId) {
      this.form.etapaOrigen = newId;
    }
  },
  methods: {
    close() {
      this.form = {
        nombreMovimiento: "",
        fecha: null,
        adjunto: "",
        etapaOrigen: this.etapaId,
        etapaDestino: null,
      }
      this.$refs.form.resetValidation()
      this.dialog = false;
    },
    submit() {
      if(this.$refs.form.validate()) {

        if(this.updating){
          this.loading = true
          ExpedienteProvider.addMovimientoExpediente(this.expedienteId, this.form)
              .then((r) => {
                this.close()
                this.$emit('movimientoAgregado', r.data.addMovimientoExpediente)
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.loading = false
              })
        }else{
          this.$emit('movimientoPreparado', this.form)
          this.close()
        }

      }
    }
  }
}
</script>

<style scoped>

</style>
