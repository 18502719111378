import graphqlClient from "../../../apollo";

class ClienteProvider {

    findCliente(id) {
        return graphqlClient.query({
            query: require('./gql/Cliente/findCliente.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchCliente() {
        return graphqlClient.query({
        query: require('./gql/Cliente/fetchCliente.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginateCliente(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Cliente/paginateCliente.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createCliente(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cliente/createCliente.graphql'),
            variables: {input}
        })
    }
    
    updateCliente(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cliente/updateCliente.graphql'),
            variables: {id, input}
        })
    }
    
     deleteCliente(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cliente/deleteCliente.graphql'),
            variables: {id}
        })
    }

}

export default new ClienteProvider()


