
const messages = {
    en: {
       role: {
            permissions: {
                EXPEDIENTE_MENU: "Access to Expediente",
                EXPEDIENTE_SHOW: "View Expediente",
                EXPEDIENTE_CREATE: "Create Expediente",
                EXPEDIENTE_UPDATE: "Modify Expediente",
                EXPEDIENTE_DELETE: "Delete Expediente",
            }
       }
    },
    es: {
        role: {
            permissions: {
                EXPEDIENTE_MENU: "Acceder Expediente",
                EXPEDIENTE_SHOW: "Ver Expediente",
                EXPEDIENTE_CREATE: "Crear Expediente",
                EXPEDIENTE_UPDATE: "Modificar Expediente",
                EXPEDIENTE_DELETE: "Eliminar Expediente",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                EXPEDIENTE_MENU: "Acessar Expediente",
                EXPEDIENTE_SHOW: "Ver Expediente",
                EXPEDIENTE_CREATE: "Criar Expediente",
                EXPEDIENTE_UPDATE: "Modificar Expediente",
                EXPEDIENTE_DELETE: "Eliminar Expediente",
            }
       }
    }
}

export default messages
    